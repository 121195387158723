import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect, { SingleValue } from "react-select";
import useI18n from "../../../hooks/useTranslations";
import {
  CableForSettings,
  NormativeForSettings,
  SteelVersionForSettings,
  VertigripSettings,
} from "../../../http/types/vertigrip/vertigripSettings";
import { getAllLanguages, Language } from "../../../types";
import Button from "../../ui/Button";
import ErrorText from "../../ui/ErrorText";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import Input from "../../ui/Input";
import { ItemsHelpModal } from "../../ui/ItemsHelpModal";
import LoadingSpinner from "../../ui/LoadingSpinner";
import ModalDialog from "../../ui/ModalDialog";
import Numeric from "../../ui/Numeric";
import reactSelectStyles from "../../ui/ReactSelectUtils";
import ScrollContent from "../../ui/ScrollContent";
import Textarea from "../../ui/Textarea";
import { NormativesHelpModal } from "./NormativesHelpModal";
import SimpleTextModalDialog from "./SimpleTextModalDialog";
import { SteelVersionsHelpModal } from "./SteelVersionsHelpModal";
import styles from "./VertigripGeneralData.module.css";

export interface VertigripGeneralDataFormBody {
  name: string;
  description: string | null;
  language: Language | null;
  heightToReach: number | null;
  normative: NormativeForSettings | null;
  steelVersion: SteelVersionForSettings | null;
  cable: CableForSettings | null;
}

interface VertigripGeneralDataProps {
  defaultValues?: VertigripGeneralDataFormBody;
  settings?: VertigripSettings;
  isLoading?: boolean;
  next: () => void;
  onSubmit: (body: VertigripGeneralDataFormBody) => Promise<void> | undefined;
  isAdmin?: boolean;
  canEdit?: boolean;
  reset: () => Promise<void>;
}

const VertigripGeneralData: FC<VertigripGeneralDataProps> = ({
  defaultValues,
  settings,
  isLoading,
  next,
  onSubmit,
  isAdmin,
  canEdit,
  reset,
}: VertigripGeneralDataProps) => {
  const i18n = useI18n();

  const languages = getAllLanguages(i18n.translation);

  const [showHeightToReachHelpModal, setShowHeightToReachHelpModal] = useState(false);
  const [showNormativesHelpModal, setShowNormativesHelpModal] = useState(false);
  const [showSteelVersionsHelpModal, setShowSteelVersionsHelpModal] = useState(false);
  const [showCablesHelpModal, setShowCablesHelpModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);

  return (
    <ScrollContent direction="row">
      <Form<VertigripGeneralDataFormBody>
        mode={"onBlur"}
        defaultValues={defaultValues}
        disabled={isLoading}
        onSubmit={() => (formData) =>
          // the admin page has a proper save button, automatic save is disabled
          // if user cannot edit, do not save, just move to next.
          isAdmin ? onSubmit(formData) : canEdit ? onSubmit(formData)?.then(() => next()) : next()}
        secondaryButton={
          <>
            {isLoading && <LoadingSpinner delayed={0} size="small" />}
            {(isAdmin || canEdit) && (
              <Button
                buttonProps={{
                  disabled: isLoading,
                  onClick: () => setShowResetModal(true),
                }}
                type="secondary"
              >
                {i18n.translation.common.reset}
              </Button>
            )}
          </>
        }
        submitText={isAdmin ? i18n.translation.common.save : i18n.translation.common.next}
      >
        {({ register, setValue, watch, control, formState: { errors }, handleSubmit, reset }) => {
          // if it is not an admin page and the user canEdit, save end set the updated data
          // !isAdmin: because the automatic save is disabled, admins have the proper save button
          const submit = () =>
            !isAdmin &&
            canEdit &&
            handleSubmit((data) => {
              onSubmit(data)?.then(() => {
                reset(data);
              });
            })();

          useEffect(() => {
            defaultValues && reset(defaultValues);
          }, [defaultValues]);

          return (
            <>
              <div className={styles.form}>
                <FormField label={i18n.translation.common.name} error={errors.name}>
                  {({ labelId, isInvalid, isOptional }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={isLoading}
                      readOnly={!canEdit}
                      {...register("name", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                      onBlur={(e) => {
                        setValue("name", e.target.value);
                        defaultValues?.name !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
                <Controller
                  name="language"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={defaultValues?.language}
                  render={({ field, fieldState }) => (
                    <FormField
                      label={`${i18n.translation.common.languages.singular} (TARGAVERT${
                        watch("language")?.value.toUpperCase() ?? "XX"
                      })`}
                      error={fieldState.error}
                    >
                      {({ labelId }) => (
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={languages}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={field.disabled || isLoading || !canEdit}
                          isLoading={isLoading}
                          getOptionLabel={(x) => x.display}
                          styles={reactSelectStyles<Language>()}
                          onChange={(value) => {
                            setValue("language", value ?? null);
                            value?.value !== field.value?.value && submit();
                          }}
                        />
                      )}
                    </FormField>
                  )}
                />
                <Controller
                  name="heightToReach"
                  control={control}
                  rules={{ required: true, min: 0.01 }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label={`${i18n.translation.common.heightToReach} (H)`}
                      error={fieldState.error}
                      showHelp={
                        settings?.texts.heightToReach
                          ? { onOpen: isLoading ? undefined : () => setShowHeightToReachHelpModal(true) }
                          : undefined
                      }
                    >
                      {({ labelId, isInvalid }) => {
                        return (
                          <>
                            <Numeric
                              {...field}
                              id={labelId}
                              isInvalid={isInvalid}
                              disabled={field.disabled || isLoading}
                              readOnly={!canEdit}
                              decimalScale={2}
                              suffix=" m"
                              allowNegative={false}
                              onBlur={(value) => {
                                if (value !== watch("heightToReach")) {
                                  setValue("heightToReach", value ?? null);
                                  submit();
                                }
                              }}
                            />
                            {!!settings?.texts.heightToReach && (
                              <SimpleTextModalDialog
                                title={i18n.translation.common.heightToReach}
                                text={settings.texts.heightToReach}
                                isOpen={showHeightToReachHelpModal}
                                onClose={() => setShowHeightToReachHelpModal(false)}
                              />
                            )}
                          </>
                        );
                      }}
                    </FormField>
                  )}
                />
                <Controller
                  name="normative"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label={i18n.translation.normatives.singular}
                      error={fieldState.error}
                      showHelp={{ onOpen: isLoading ? undefined : () => setShowNormativesHelpModal(true) }}
                    >
                      {({ labelId }) => {
                        const normatives = settings?.normatives ?? [];
                        return (
                          <>
                            <ReactSelect
                              {...field}
                              inputId={labelId}
                              options={normatives}
                              placeholder={`${i18n.translation.common.select}...`}
                              isDisabled={field.disabled || isLoading || !canEdit}
                              isLoading={isLoading}
                              getOptionValue={(x) => x.id}
                              getOptionLabel={(x) => x.name}
                              styles={reactSelectStyles<NormativeForSettings>(fieldState.invalid)}
                              onChange={(selectedOption) => {
                                setValue("normative", selectedOption ?? null);
                                selectedOption?.id !== field.value?.id && submit();
                              }}
                            />
                            <NormativesHelpModal
                              onClose={() => setShowNormativesHelpModal(false)}
                              isOpen={showNormativesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (normative) => {
                                      setValue("normative", normatives.find((x) => x.id == normative.id) ?? null);
                                      setShowNormativesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={normatives}
                            />
                          </>
                        );
                      }}
                    </FormField>
                  )}
                />
                <Controller
                  name="steelVersion"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label={i18n.translation.steelVersions.singular}
                      error={fieldState.error}
                      showHelp={{ onOpen: isLoading ? undefined : () => setShowSteelVersionsHelpModal(true) }}
                    >
                      {({ labelId }) => {
                        const updateSteelVersion = (
                          value?: SteelVersionForSettings | SingleValue<SteelVersionForSettings>
                        ) => {
                          if (value?.id !== field.value?.id) {
                            setValue("steelVersion", value ?? null);
                            submit();
                          }
                        };

                        const steelVersions = settings?.steelVersions ?? [];

                        return (
                          <>
                            <ReactSelect
                              {...field}
                              inputId={labelId}
                              options={steelVersions}
                              placeholder={`${i18n.translation.common.select}...`}
                              isDisabled={field.disabled || isLoading || !canEdit}
                              isLoading={isLoading}
                              getOptionValue={(x) => x.id}
                              getOptionLabel={(x) => x.name}
                              styles={reactSelectStyles<SteelVersionForSettings>(fieldState.invalid)}
                              onChange={updateSteelVersion}
                            />
                            <SteelVersionsHelpModal
                              onClose={() => setShowSteelVersionsHelpModal(false)}
                              isOpen={showSteelVersionsHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (steelVersion) => {
                                      updateSteelVersion(steelVersions.find((x) => x.id === steelVersion.id));
                                      setShowSteelVersionsHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={steelVersions}
                            />
                          </>
                        );
                      }}
                    </FormField>
                  )}
                />
                <Controller
                  name="cable"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <FormField
                      label={i18n.translation.cables.singular}
                      error={fieldState.error}
                      hidden={true}
                      showHelp={{ onOpen: isLoading ? undefined : () => setShowCablesHelpModal(true) }}
                    >
                      {({ labelId }) => {
                        const updateCable = (value?: CableForSettings | SingleValue<CableForSettings>) => {
                          if (value?.id !== field.value?.id) {
                            setValue("cable", value ?? null);
                            submit();
                          }
                        };

                        const cables = settings?.cables ?? [];

                        return (
                          <>
                            <ReactSelect
                              {...field}
                              inputId={labelId}
                              options={cables}
                              placeholder={`${i18n.translation.common.select}...`}
                              isDisabled={field.disabled || isLoading || !canEdit}
                              isLoading={isLoading}
                              getOptionValue={(x) => x.id}
                              getOptionLabel={(x) => x.code}
                              styles={reactSelectStyles<CableForSettings>(fieldState.invalid)}
                              onChange={updateCable}
                            />
                            <ItemsHelpModal
                              title={i18n.translation.cables.plural}
                              onClose={() => setShowCablesHelpModal(false)}
                              isOpen={showCablesHelpModal}
                              onSelect={(cable) => {
                                updateCable(cables.find((x) => x.id === cable.id));
                                setShowCablesHelpModal(false);
                              }}
                              items={cables.map((x) => ({
                                id: x.id,
                                title: x.code,
                                description: x.description,
                                name: x.name,
                                image: x.image,
                              }))}
                            />
                          </>
                        );
                      }}
                    </FormField>
                  )}
                />
                <FormField label={i18n.translation.common.description} error={errors.description} isOptional>
                  {({ labelId, isInvalid, isOptional }) => (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={!canEdit}
                      {...register("description", { required: !isOptional })}
                      onBlur={(e) => {
                        if (defaultValues?.description !== e.target.value) {
                          setValue("description", e.target.value);
                          submit();
                        }
                      }}
                    />
                  )}
                </FormField>
              </div>
            </>
          );
        }}
      </Form>
      <div className={styles.heightToReachImage}>
        <img src={window.location.origin + "/vertigrip_height_to_reach.jpg"} />
      </div>
      <ModalDialog
        isOpen={!!showResetModal}
        onClose={() => setShowResetModal(false)}
        title={i18n.translation.configurations.reset}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setShowResetModal(false),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => reset().then(() => setShowResetModal(false)),
          },
        ]}
      >
        <ErrorText text={i18n.translation.configurations.questions.reset} />
      </ModalDialog>
    </ScrollContent>
  );
};

export default VertigripGeneralData;
