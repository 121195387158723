import { Glyph } from "../../types";

const SvgLogo1: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.3 52.2" {...props}>
      <path
        fill="#de253d"
        d="M70.6,24.4l-8.8,6.4c-.4-1-.9-2.1-1.6-3-.6-.8-1.2-1.5-2-2.1l8.5-6.2c.4.9.8,1.7,1.4,2.5.7.9,1.5,1.8,2.4,2.4Z"
      />
      <path
        fill="#de253d"
        d="M58.9,36.1c-.6,4.2-4.2,7.2-8.4,7.2h-1.3c-4.6-.7-7.8-5-7.1-9.6.6-4.2,4.2-7.2,8.4-7.2h1.3c1.3.2,2.5.7,3.5,1.4,1.8,1.2,3,3,3.5,5.1.2,1,.3,2,.1,3.1Z"
      />
      <path
        fill="#de253d"
        d="M86.2,16c-.6,4.2-4.2,7.2-8.4,7.2h-1.3c-1-.2-2-.5-2.8-1-1.9-1-3.3-2.8-4-4.9-.4-1.2-.5-2.5-.3-3.8.6-4.2,4.2-7.2,8.4-7.2h1.3c4.6.7,7.8,5,7.1,9.6Z"
      />
      <path d="M32.1,25.5c3.3-1.7,4.8-4.2,4.8-8v-7.1C36.9,3.4,33.6,0,26.5,0H0v52.2h27.1c7,0,10.4-3.3,10.4-10.4v-8.3c0-3.8-1.7-6.6-5.4-8ZM8.9,8.1h16.4c2.1,0,2.8.8,2.8,2.8v7.9c0,2.1-.8,2.8-2.8,2.8H8.9v-13.5ZM28.7,41.3c0,2.1-.8,2.8-2.8,2.8h-.1c0,0-16.9,0-16.9,0v-14.7h17c2.1,0,2.8.8,2.8,2.8v9.2Z" />
      <g>
        <path d="M90.7,41.8v-6.1h8.8v5.7c0,2.1.7,2.8,2.8,2.8h14.2c2,0,2.8-.7,2.8-2.8v-6.3c0-2.4-1-3.1-3.4-3.7l-14.2-3.1c-6.3-1.4-10.7-3.7-10.7-10.7v-7.2C91,3.3,94.4,0,101.4,0h15.6C124.1,0,127.4,3.4,127.4,10.4v5.6h-8.7v-5.2c0-2.1-.7-2.8-2.8-2.8h-13.1c-2.1,0-2.8.7-2.8,2.8v5.5c0,2.4,1,3.1,3.5,3.7l14.1,3.1c6.3,1.4,10.7,3.7,10.7,10.7v7.9c0,7.1-3.4,10.4-10.4,10.4h-16.6c-7.1,0-10.4-3.4-10.4-10.4h-.2Z" />
        <path d="M162.9,0h-12.5l-17,52.2h9.4l4-12.8h19.2l4,12.8h9.8L162.9,0ZM149.2,31.2l7.2-23.2,7.2,23.2h-14.4Z" />
        <path d="M187.7,0h34.4v8.2h-25.4v13.7h21v8.1h-21v22.1h-8.9V0h-.1Z" />
        <path d="M230.9,0h34.4v8.2h-25.4v13.3h21v8.1h-21v14.5h25.4v8.2h-34.4V0Z" />
      </g>
    </svg>
  );
};

export default SvgLogo1;
