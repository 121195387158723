import { PropsWithChildren } from "react";
import useI18n from "../../hooks/useTranslations";
import SvgClose from "../icons/Close";
import styles from "./ControlledNumericWithQuantity.module.css";
import Icon from "./Icon";
import Input from "./Input";
import Numeric from "./Numeric";

interface ControlledNumericWithQuantityProps {
  item: {
    id: string;
    display: string;
    quantity: number;
    scale: number;
  };
  disabled?: {
    display?: boolean;
    quantity?: boolean;
    controls?: boolean;
  };
  itemReadonly?: boolean;
  allowNegative?: boolean;
  decimalScale?: number;
  onUpdateQuantity: (id: string, quantity: number) => void;
  onRemove?: (id: string) => void;
}

const ControlledNumericWithQuantity = ({
  item,
  disabled,
  itemReadonly,
  allowNegative = false,
  decimalScale = 0,
  onUpdateQuantity,
  onRemove: remove,
}: ControlledNumericWithQuantityProps) => {
  const i18n = useI18n();

  return (
    <div className={styles.item}>
      <Input value={item.display} disabled={disabled?.display || itemReadonly} />
      <Numeric
        min={0}
        width="50px"
        value={item.quantity}
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        disabled={disabled?.quantity || disabled?.controls}
        onBlur={(value) => onUpdateQuantity(item.id, value ?? 0)}
        controls={{
          scale: item.scale,
          onUpdate: (value) => onUpdateQuantity(item.id, value),
        }}
      />
      {!!remove && (
        <button
          title={i18n.translation.common.remove}
          className={styles.itemButton}
          disabled={disabled?.controls}
          onClick={(e) => {
            e.preventDefault();
            remove(item.id);
          }}
        >
          <Icon glyph={SvgClose} />
        </button>
      )}
    </div>
  );
};

const List = ({ children }: PropsWithChildren) => {
  return <div className={styles.items}>{children}</div>;
};

ControlledNumericWithQuantity.List = List;

export default ControlledNumericWithQuantity;
