import classNames from "classnames";

import { FC, Fragment } from "react";
import useI18n from "../../../hooks/useTranslations";
import { WallMaterialFixingForSettings } from "../../../http/types/vertigrip/vertigripSettings";
import { getMinutesRoundedTimestamp } from "../../../utils";
import BorderBox from "../../ui/BorderBox";
import ModalDialog from "../../ui/ModalDialog";
import styles from "./WallMaterialFixingsHelpModal.module.css";

type WallMaterialFixingsHelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (item: WallMaterialFixingForSettings) => void;
  items: WallMaterialFixingForSettings[];
};

export const WallMaterialFixingsHelpModal: FC<WallMaterialFixingsHelpModalProps> = ({
  items,
  isOpen,
  onClose,
  onSelect,
}: WallMaterialFixingsHelpModalProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog
      title={i18n.translation.wallMaterialFixings.plural}
      isOpen={isOpen}
      onClose={onClose}
      actions={[{ title: i18n.translation.common.close, onClick: onClose }]}
      fitContent
    >
      <div
        className={classNames(styles.grid, {
          [styles.grid1]: items.length === 1,
          [styles.grid2]: items.length === 2,
          [styles.grid3]: items.length > 2,
        })}
      >
        {items.map((item) => (
          <Fragment key={item.id}>
            <BorderBox
              onClick={onSelect ? () => onSelect(item) : undefined}
              showIsMissing={true}
              title={item.name}
              description={item.description}
            >
              <div className={styles.img}>
                {item.image ? (
                  <BorderBox.Image
                    src={`${import.meta.env.VITE_BUCKET_URL}${item.image}?${getMinutesRoundedTimestamp()}`}
                  />
                ) : (
                  BorderBox.PlaceHolder
                )}
              </div>
            </BorderBox>
          </Fragment>
        ))}
      </div>
    </ModalDialog>
  );
};
