import { FC, useContext, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import NotificationDispatch, { showErrorNotification } from "../../../../context/notificationContext";
import { createLoadOptions } from "../../../../hooks/useDebounce";
import useI18n from "../../../../hooks/useTranslations";
import { SearchAdminItem } from "../../../../http/types/items";
import { useItemsApi } from "../../../../http/useItems";
import { useAdminHandlesApi } from "../../../../http/vertigrip/useHandles";
import Button from "../../../ui/Button";
import Form from "../../../ui/Form";
import FormField from "../../../ui/FormField";
import FormFieldsContainer from "../../../ui/FormFieldsContainer";
import ModalDialog from "../../../ui/ModalDialog";
import reactSelectStyles from "../../../ui/ReactSelectUtils";
import Switch from "../../../ui/Switch";

interface HandleForm {
  item: SearchAdminItem | null;
  isVisible: boolean;
}

const CreateHandleModalDialogButton: FC = () => {
  const i18n = useI18n();
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const itemsApi = useItemsApi();
  const handelsApi = useAdminHandlesApi();

  return (
    <>
      <Button buttonProps={{ onClick: () => setShowNewItemDialog(true) }}>{i18n.translation.common.createNew}</Button>
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.handles.addItemAsVertigrip}
        minHeight="350px"
      >
        <Form<HandleForm>
          defaultValues={{ item: null, isVisible: false }}
          submitText={i18n.translation.common.save}
          disabled={isSubmitting}
          secondaryButton={
            <Button buttonProps={{ onClick: () => setShowNewItemDialog(false) }} type="secondary">
              {i18n.translation.common.cancel}
            </Button>
          }
          onSubmit={() => (formData) =>
            handelsApi
              .insertAdminHandle({ itemId: formData.item?.id!, isVisible: formData.isVisible })
              .then(() => setShowNewItemDialog(false))
              .catch((err) => {
                dispatch(showErrorNotification(i18n, err));
                setIsSubmitting(false);
              })}
        >
          {({ control, setValue, watch }) => {
            const visible = !!watch("isVisible");

            return (
              <FormFieldsContainer fullWidth>
                <Controller
                  name="item"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormField label={i18n.translation.items.singular} error={fieldState.error}>
                        {({ labelId }) => {
                          const searchTerm = useRef("");
                          const aborter = useRef(new AbortController());

                          const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);

                          return (
                            <AsyncSelect
                              {...field}
                              inputId={labelId}
                              loadOptions={loadOptions}
                              isClearable
                              getOptionLabel={(x) => x.code}
                              getOptionValue={(x) => x.id}
                              styles={reactSelectStyles<SearchAdminItem>()}
                              onChange={(value) => setValue("item", value ?? null)}
                            />
                          );
                        }}
                      </FormField>
                    );
                  }}
                />
                <FormField label={i18n.translation.common.isVisible}>
                  {({ labelId }) => (
                    <Switch id={labelId} checked={visible} onChange={(checked) => setValue("isVisible", checked)} />
                  )}
                </FormField>
              </FormFieldsContainer>
            );
          }}
        </Form>
      </ModalDialog>
    </>
  );
};

export default CreateHandleModalDialogButton;
