import { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import useI18n from "../../../hooks/useTranslations";
import {
  InstallationTypeForSettings,
  IntermediateElementForSettings,
  ShuttleForSettings,
  SteelVersionForSettings,
  SystemTypeForSettings,
  VertigripSettings,
  WallMaterialFixingForSettings,
  WallMaterialForSettings,
} from "../../../http/types/vertigrip/vertigripSettings";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import { ItemsHelpModal } from "../../ui/ItemsHelpModal";
import LoadingSpinner from "../../ui/LoadingSpinner";
import Numeric from "../../ui/Numeric";
import reactSelectStyles, { BooleanSelectValue, getBooleanValues } from "../../ui/ReactSelectUtils";
import ScrollContent from "../../ui/ScrollContent";
import SimpleTextModalDialog from "./SimpleTextModalDialog";
import styles from "./VertigripIntermediateFixation.module.css";
import { WallMaterialFixingsHelpModal } from "./WallMaterialFixingsHelpModal";
import { WallMaterialsHelpModal } from "./WallMaterialsHelpModal";

export interface VertigripIntermediateFixationFormBody {
  intermediates: number | null;
  systemType: SystemTypeForSettings | null;
  shuttle: ShuttleForSettings | null;
  fixedInstallationType: boolean | null;
  installationType: InstallationTypeForSettings | null;
  intermediateElement: IntermediateElementForSettings | null;
  wallMaterial: WallMaterialForSettings | null;
  noWallMaterial: boolean;
  fixing: WallMaterialFixingForSettings | null;
}

interface VertigripIntermediateFixationProps {
  totalHeight: number;
  defaultValues?: VertigripIntermediateFixationFormBody;
  steelVersion?: SteelVersionForSettings | null;
  defaultInstallationType?: InstallationTypeForSettings | null;
  settings?: VertigripSettings;
  isLoading?: boolean;
  back: () => void;
  next: () => void;
  onSubmit: (body: VertigripIntermediateFixationFormBody) => Promise<void> | undefined;
  isAdmin?: boolean;
  canEdit?: boolean;
}

const VertigripIntermediateFixation: FC<VertigripIntermediateFixationProps> = ({
  totalHeight,
  defaultValues,
  steelVersion,
  defaultInstallationType,
  settings,
  isLoading,
  back,
  next,
  onSubmit,
  isAdmin,
  canEdit,
}: VertigripIntermediateFixationProps) => {
  const i18n = useI18n();

  const [showNrIntermediatesHelpModal, setShowNrIntermediatesHelpModal] = useState(false);
  const [showInstallationTypesHelpModal, setShowInstallationTypesHelpModal] = useState(false);
  const [showPassingThroughInstallationTypesHelpModal, setShowPassingThroughInstallationTypesHelpModal] =
    useState(false);
  const [showSystemTypesHelpModal, setShowSystemTypesHelpModal] = useState(false);
  const [showIntermediateElementsHelpModal, setShowIntermediateElementsHelpModal] = useState(false);
  const [showShuttlesHelpModal, setShowShuttlesHelpModal] = useState(false);
  const [showWallMaterialsHelpModal, setShowWallMaterialsHelpModal] = useState(false);
  const [showWallMaterialFixingsHelpModal, setShowWallMaterialFixingsHelpModal] = useState(false);

  const fakeShuttle: ShuttleForSettings = {
    id: "fakeId",
    code: i18n.translation.shuttles.no,
    description: "",
  };

  const fakeWallMaterial: WallMaterialForSettings = {
    id: "fakeId",
    name: i18n.translation.wallMaterials.noWallMaterial,
    description: "",
    fixings: [],
  };

  const tmpValues = useRef({
    installationType: defaultValues?.installationType,
    intermediateElement: defaultValues?.intermediateElement,
  });

  const booleanValues = getBooleanValues(i18n.translation);

  return (
    <ScrollContent direction="row">
      <Form<VertigripIntermediateFixationFormBody>
        mode={"onBlur"}
        defaultValues={defaultValues}
        disabled={isLoading}
        onSubmit={() => (formData) =>
          // the admin page has a proper save button, automatic save is disabled
          // if user cannot edit, do not save, just move to next.
          isAdmin ? onSubmit(formData) : canEdit ? onSubmit(formData)?.then(() => next()) : next()}
        submitText={isAdmin ? i18n.translation.common.save : i18n.translation.common.next}
        secondaryButton={
          <>
            {isLoading && <LoadingSpinner delayed={0} size="small" />}
            {!isAdmin ? (
              <Button
                buttonProps={{
                  disabled: isLoading,
                  onClick: back,
                }}
                type="secondary"
              >
                {i18n.translation.common.back}
              </Button>
            ) : undefined}
          </>
        }
      >
        {({ setValue, watch, control, handleSubmit, reset }) => {
          // if it is not an admin page and the user canEdit, save end set the updated data
          // !isAdmin: because the automatic save is disabled, admins have the proper save button
          const submit = () =>
            !isAdmin &&
            canEdit &&
            handleSubmit((data) => {
              onSubmit(data)?.then(() => {
                reset(data);
              });
            })();

          useEffect(() => {
            defaultValues && reset(defaultValues);
          }, [defaultValues]);

          const systemType = watch("systemType");
          const installationType = watch("installationType");
          const wallMaterial = watch("wallMaterial");

          return (
            <div className={styles.form}>
              <Controller
                name="intermediates"
                control={control}
                rules={{ required: true, min: 0 }}
                defaultValue={defaultValues?.intermediates}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.common.nrIntermediatesAndSpan}
                    error={fieldState.error}
                    showHelp={
                      settings?.texts.intermediatesAndSpan
                        ? { onOpen: isLoading ? undefined : () => setShowNrIntermediatesHelpModal(true) }
                        : undefined
                    }
                  >
                    {({ labelId, isInvalid }) => {
                      const intermediates = watch("intermediates");
                      const intermediatesSpan = totalHeight / ((intermediates ?? 0) + 1);

                      return (
                        <div className={styles.inermediates}>
                          <Numeric
                            {...field}
                            id={labelId}
                            min={0}
                            isInvalid={isInvalid}
                            decimalScale={0}
                            allowNegative={false}
                            disabled={field.disabled || isLoading}
                            readOnly={!canEdit}
                            onBlur={(value) => {
                              if (value !== watch("intermediates")) {
                                setValue("intermediates", value);
                                submit();
                              }
                            }}
                            controls={
                              canEdit
                                ? {
                                    onUpdate: (value) => {
                                      setValue("intermediates", value);
                                      !isAdmin && submit();
                                    },
                                    scale: 1,
                                  }
                                : undefined
                            }
                          />
                          {!!showNrIntermediatesHelpModal && !!settings?.texts.intermediatesAndSpan && (
                            <SimpleTextModalDialog
                              title={i18n.translation.common.nrIntermediatesAndSpan}
                              text={settings.texts.intermediatesAndSpan}
                              isOpen={showNrIntermediatesHelpModal}
                              onClose={() => setShowNrIntermediatesHelpModal(false)}
                            />
                          )}
                          <Numeric
                            readOnly
                            decimalScale={2}
                            isInvalid={intermediatesSpan >= 5}
                            disabled={field.disabled || isLoading}
                            suffix=" m"
                            value={isLoading || !intermediates ? "" : intermediatesSpan}
                          />
                        </div>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="systemType"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.systemType}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.systemTypes.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowSystemTypesHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateSystemType = (value?: SystemTypeForSettings | SingleValue<SystemTypeForSettings>) => {
                        if (value?.id !== field.value?.id) {
                          const systemType = value ?? null;
                          setValue("systemType", systemType);
                          setValue("shuttle", systemType?.shuttles[0] ?? null);

                          if (systemType?.isPassingThrough) {
                            const fixedInstallationType = true;
                            setValue("fixedInstallationType", fixedInstallationType);
                            setValue("installationType", null);

                            const intermediateElement =
                              systemType?.intermediateElements?.find(
                                (x) => x.fixed === fixedInstallationType && steelVersion?.id === x.steelVersionId
                              )?.items ?? [];
                            setValue("intermediateElement", intermediateElement[0] ?? null);
                          } else {
                            setValue("fixedInstallationType", null);
                            const installationType =
                              steelVersion?.installationTypes.find(
                                (x) => x.id == tmpValues?.current.installationType?.id
                              ) ?? defaultInstallationType;
                            setValue("installationType", installationType ?? null);

                            const intermediateElement = installationType?.intermediateElements ?? [];
                            setValue("intermediateElement", intermediateElement[0] ?? null);
                          }

                          submit();
                        }
                      };

                      const systemTypes = settings?.systemTypes ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={systemTypes}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<SystemTypeForSettings>()}
                            onChange={updateSystemType}
                          />
                          {!!showSystemTypesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.systemTypes.plural}
                              onClose={() => setShowSystemTypesHelpModal(false)}
                              isOpen={showSystemTypesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (systemType) => {
                                      updateSystemType(systemTypes.find((x) => x.id === systemType.id));
                                      setShowSystemTypesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={systemTypes.map((x) => ({
                                id: x.id,
                                title: x.name,
                                description: x.description,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="fixedInstallationType"
                control={control}
                defaultValue={defaultValues?.fixedInstallationType}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.installationTypes.singular}
                    hidden={!systemType?.isPassingThrough}
                    error={fieldState.error}
                    showHelp={{
                      onOpen: isLoading ? undefined : () => setShowPassingThroughInstallationTypesHelpModal(true),
                    }}
                  >
                    {({ labelId }) => {
                      const updateFixedInstallationType = (
                        value?: BooleanSelectValue | SingleValue<BooleanSelectValue>
                      ) => {
                        if (value?.value !== field.value) {
                          field.onChange(value?.value === true);
                          const intermediateElement =
                            systemType?.intermediateElements?.find(
                              (x) => x.fixed === value?.value && steelVersion?.id === x.steelVersionId
                            )?.items[0] ?? null;
                          setValue("intermediateElement", intermediateElement);
                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={booleanValues}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionLabel={(item) =>
                              item.value === true ? i18n.translation.common.fixed : i18n.translation.common.removable
                            }
                            getOptionValue={(x) => x.value.toString()}
                            styles={reactSelectStyles<BooleanSelectValue>()}
                            onChange={updateFixedInstallationType}
                            value={booleanValues.find((option) => option.value === field.value) ?? null}
                          />
                          {!!showPassingThroughInstallationTypesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.installationTypes.plural}
                              onClose={() => setShowPassingThroughInstallationTypesHelpModal(false)}
                              isOpen={showPassingThroughInstallationTypesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (value) => {
                                      updateFixedInstallationType(booleanValues.find((x) => `${x.value}` === value.id));
                                      setShowPassingThroughInstallationTypesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={booleanValues.map((x) => ({
                                id: `${x.value}`,
                                title: x.value ? i18n.translation.common.fixed : i18n.translation.common.removable,
                                description: "",
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="installationType"
                control={control}
                rules={{ required: !systemType?.isPassingThrough }}
                defaultValue={defaultValues?.installationType}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.installationTypes.singular}
                    hidden={!!systemType?.isPassingThrough}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowInstallationTypesHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateInstallationType = (
                        value?: InstallationTypeForSettings | SingleValue<InstallationTypeForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          const installationType = value ?? null;
                          setValue("installationType", installationType);
                          tmpValues.current.installationType = installationType;

                          const intermediateElement = installationType?.intermediateElements[0] ?? null;
                          setValue("intermediateElement", intermediateElement);
                          tmpValues.current.intermediateElement = intermediateElement;

                          setValue("wallMaterial", null);
                          setValue("fixing", null);
                          setValue("noWallMaterial", false);

                          submit();
                        }
                      };

                      const installationTypes = steelVersion?.installationTypes ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={installationTypes}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<InstallationTypeForSettings>()}
                            onChange={updateInstallationType}
                          />
                          {!!showInstallationTypesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.installationTypes.plural}
                              onClose={() => setShowInstallationTypesHelpModal(false)}
                              isOpen={showInstallationTypesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (installationType) => {
                                      updateInstallationType(
                                        installationTypes.find((x) => x.id == installationType.id)
                                      );
                                      setShowInstallationTypesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={installationTypes.map((x) => ({
                                id: x.id,
                                title: x.name,
                                description: x.description,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="intermediateElement"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.intermediateElement}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.intermediateElements.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowIntermediateElementsHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const fixedInstallationType = watch("fixedInstallationType");

                      const options: IntermediateElementForSettings[] =
                        installationType?.intermediateElements ??
                        systemType?.intermediateElements?.find(
                          (x) => x.fixed === fixedInstallationType && steelVersion?.id === x.steelVersionId
                        )?.items ??
                        [];

                      const updateIntermediateElement = (
                        value?: IntermediateElementForSettings | SingleValue<IntermediateElementForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("intermediateElement", value ?? null);
                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={options}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.code}
                            styles={reactSelectStyles<IntermediateElementForSettings>()}
                            onChange={(selectedOption) => {
                              if (selectedOption?.id !== field.value?.id) {
                                setValue("intermediateElement", selectedOption ?? null);
                                submit();
                              }
                            }}
                          />
                          {!!showIntermediateElementsHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.intermediateElements.plural}
                              onClose={() => setShowIntermediateElementsHelpModal(false)}
                              isOpen={showIntermediateElementsHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (intermediateElement) => {
                                      updateIntermediateElement(options.find((x) => x.id === intermediateElement.id));
                                      setShowIntermediateElementsHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={options.map((x) => ({
                                id: x.id,
                                title: x.code,
                                description: x.description,
                                name: x.name,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="shuttle"
                control={control}
                defaultValue={defaultValues?.shuttle}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.shuttles.singular}
                    error={fieldState.error}
                    showHelp={
                      systemType?.shuttles.length
                        ? { onOpen: isLoading ? undefined : () => setShowShuttlesHelpModal(true) }
                        : undefined
                    }
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateShuttle = (value?: ShuttleForSettings | SingleValue<ShuttleForSettings>) => {
                        if (value?.id !== (field.value ?? fakeShuttle).id) {
                          setValue("shuttle", value && value.id != fakeShuttle.id ? value : null);
                          submit();
                        }
                      };

                      const shuttles = systemType?.shuttles ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={[...shuttles, fakeShuttle]}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.code}
                            styles={reactSelectStyles<ShuttleForSettings>()}
                            onChange={updateShuttle}
                            value={shuttles.find((x) => x.id == field.value?.id) ?? fakeShuttle}
                          />
                          {!!showShuttlesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.shuttles.plural}
                              onClose={() => setShowShuttlesHelpModal(false)}
                              isOpen={showShuttlesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (shuttle) => {
                                      updateShuttle(shuttles.find((x) => x.id === shuttle.id));
                                      setShowShuttlesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={shuttles.map((x) => ({
                                id: x.id,
                                title: x.code,
                                description: x.description,
                                name: x.name,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="wallMaterial"
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterials.singular}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowWallMaterialsHelpModal(true) }}
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateWallMaterial = (
                        value?: WallMaterialForSettings | SingleValue<WallMaterialForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("noWallMaterial", value?.id == fakeWallMaterial.id);
                          setValue("wallMaterial", value ?? null);
                          setValue("fixing", value?.fixings[0] ?? null);

                          submit();
                        }
                      };

                      const wallMaterials = installationType?.wallMaterials ?? [];
                      const noWallMaterial = watch("noWallMaterial");

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={[fakeWallMaterial, ...wallMaterials]}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterial}
                            value={wallMaterial ?? (noWallMaterial ? fakeWallMaterial : null)}
                          />
                          <WallMaterialsHelpModal
                            onClose={() => setShowWallMaterialsHelpModal(false)}
                            isOpen={showWallMaterialsHelpModal}
                            onSelect={
                              isAdmin || canEdit
                                ? (selectedOption) => {
                                    updateWallMaterial(wallMaterials.find((x) => x.id == selectedOption.id));
                                    setShowWallMaterialsHelpModal(false);
                                  }
                                : undefined
                            }
                            items={wallMaterials}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="fixing"
                control={control}
                defaultValue={defaultValues?.fixing}
                disabled={!wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterialFixings.singular}
                    disabled={field.disabled || !canEdit}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={
                      !wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id
                        ? undefined
                        : { onOpen: () => setShowWallMaterialFixingsHelpModal(true) }
                    }
                  >
                    {({ labelId }) => {
                      const updateWallMaterialFixing = (
                        value?: WallMaterialFixingForSettings | SingleValue<WallMaterialFixingForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("fixing", value ?? null);

                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={wallMaterial?.fixings ?? []}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialFixingForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterialFixing}
                          />
                          <WallMaterialFixingsHelpModal
                            onClose={() => setShowWallMaterialFixingsHelpModal(false)}
                            isOpen={showWallMaterialFixingsHelpModal}
                            onSelect={
                              isAdmin || canEdit
                                ? (selectedOption) => {
                                    updateWallMaterialFixing(
                                      wallMaterial?.fixings?.find((x) => x.id == selectedOption.id)
                                    );
                                    setShowWallMaterialFixingsHelpModal(false);
                                  }
                                : undefined
                            }
                            items={wallMaterial?.fixings ?? []}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
            </div>
          );
        }}
      </Form>
    </ScrollContent>
  );
};

export default VertigripIntermediateFixation;
