import classNames from "classnames";

import { FC, Fragment } from "react";
import useI18n from "../../hooks/useTranslations";
import { getMinutesRoundedTimestamp } from "../../utils";
import BorderBox from "./BorderBox";
import styles from "./ItemsHelpModal.module.css";
import ModalDialog from "./ModalDialog";

interface item {
  id: string;
  title: string;
  description?: string;
  name?: string;
  image?: string;
}

type ItemsHelpModalProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (item: item) => void;
  items: item[];
};

export const ItemsHelpModal: FC<ItemsHelpModalProps> = ({
  title,
  items,
  isOpen,
  onClose,
  onSelect,
}: ItemsHelpModalProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      actions={[{ title: i18n.translation.common.close, onClick: onClose }]}
    >
      <div
        className={classNames(styles.grid, {
          [styles.grid1]: items.length === 1,
          [styles.grid2]: items.length > 1,
        })}
      >
        {items.map((item) => (
          <Fragment key={item.id}>
            <BorderBox
              onClick={onSelect ? () => onSelect(item) : undefined}
              showIsMissing={true}
              title={item.title}
              subTitle={item.name}
              description={item.description}
            >
              <div className={styles.img}>
                {item.image ? (
                  <BorderBox.Image
                    src={`${import.meta.env.VITE_BUCKET_URL}${item.image}?${getMinutesRoundedTimestamp()}`}
                  />
                ) : (
                  BorderBox.PlaceHolder
                )}
              </div>
            </BorderBox>
          </Fragment>
        ))}
      </div>
    </ModalDialog>
  );
};
