import { FC, useContext, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import NotificationDispatch, { showErrorNotification } from "../../../../../../context/notificationContext";
import { createLoadOptions } from "../../../../../../hooks/useDebounce";
import useI18n from "../../../../../../hooks/useTranslations";
import { SearchAdminItem } from "../../../../../../http/types/items";
import { useItemsApi } from "../../../../../../http/useItems";
import { useAdminShuttlesApi } from "../../../../../../http/vertigrip/useShuttles";
import Button from "../../../../../ui/Button";
import Form from "../../../../../ui/Form";
import FormField from "../../../../../ui/FormField";
import ModalDialog from "../../../../../ui/ModalDialog";
import Numeric from "../../../../../ui/Numeric";
import reactSelectStyles from "../../../../../ui/ReactSelectUtils";
import Switch from "../../../../../ui/Switch";

interface ShuttleForm {
  item: SearchAdminItem | null;
  order: number | null;
  isVisible: boolean;
}

const CreateShuttleModalDialogButton: FC = () => {
  const i18n = useI18n();
  const { id: systemTypeId } = useParams<{ id: string }>();
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const itemsApi = useItemsApi();
  const shuttlesApi = useAdminShuttlesApi();

  return (
    <>
      <Button buttonProps={{ onClick: () => setShowNewItemDialog(true) }}>{i18n.translation.common.createNew}</Button>
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.shuttles.addItemAsVertigrip}
        minHeight="350px"
      >
        <Form<ShuttleForm>
          defaultValues={{ item: null, order: 50, isVisible: false }}
          submitText={i18n.translation.common.save}
          disabled={isSubmitting}
          secondaryButton={
            <Button buttonProps={{ onClick: () => setShowNewItemDialog(false) }} type="secondary">
              {i18n.translation.common.cancel}
            </Button>
          }
          onSubmit={() => (formData) =>
            shuttlesApi
              .insertAdminShuttle({
                itemId: formData.item?.id!,
                order: formData.order!,
                systemTypeId: systemTypeId!,
                isVisible: formData.isVisible,
              })
              .then(() => setShowNewItemDialog(false))
              .catch((err) => {
                dispatch(showErrorNotification(i18n, err));
                setIsSubmitting(false);
              })}
        >
          {({ control, setValue, watch }) => {
            const visible = !!watch("isVisible");

            return (
              <>
                <Controller
                  name="item"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <FormField label={i18n.translation.items.singular} error={fieldState.error}>
                        {({ labelId }) => {
                          const searchTerm = useRef("");
                          const aborter = useRef(new AbortController());

                          const loadOptions = createLoadOptions(aborter, searchTerm, itemsApi.getAdminItems);

                          return (
                            <AsyncSelect
                              {...field}
                              inputId={labelId}
                              loadOptions={loadOptions}
                              isClearable
                              getOptionLabel={(x) => x.code}
                              getOptionValue={(x) => x.id}
                              styles={reactSelectStyles<SearchAdminItem>()}
                              onChange={(value) => setValue("item", value ?? null)}
                            />
                          );
                        }}
                      </FormField>
                    );
                  }}
                />
                <FormField label={i18n.translation.common.isVisible}>
                  {({ labelId }) => (
                    <Switch id={labelId} checked={visible} onChange={(checked) => setValue("isVisible", checked)} />
                  )}
                </FormField>
                <Controller
                  name="order"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.order} error={fieldState.error}>
                      {({ labelId, isInvalid }) => (
                        <Numeric
                          {...field}
                          id={labelId}
                          isInvalid={isInvalid}
                          decimalScale={0}
                          allowNegative={false}
                          onBlur={(value) => setValue("order", value)}
                        />
                      )}
                    </FormField>
                  )}
                />
              </>
            );
          }}
        </Form>
      </ModalDialog>
    </>
  );
};

export default CreateShuttleModalDialogButton;
