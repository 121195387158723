import classNames from "classnames";
import { ImgHTMLAttributes, PropsWithChildren } from "react";
import SvgNoImage from "../icons/SvgNoImage";
import styles from "./BorderBox.module.css";
import Icon from "./Icon";
import WhiteSpacePreWrapText from "./WhiteSpacePreWrapText";

type BorderBoxProps = PropsWithChildren<{
  onClick?: () => void;
  title?: string;
  subTitle?: string;
  description?: string;
  showIsMissing?: boolean;
  className?: string;
}>;

const BorderBox = ({ onClick, title, subTitle, description, children, showIsMissing, className }: BorderBoxProps) => (
  <div
    className={classNames(className, {
      [styles.border]: showIsMissing || !!children,
      [styles.link]: !!onClick,
    })}
    onClick={onClick}
  >
    {(showIsMissing || !!children) && <div className={styles.imgContainer}>{children}</div>}
    <div>
      {!!title && <div className={classNames(styles.title, { [styles.center]: !description })}>{title}</div>}
      {!!subTitle && <div className={classNames(styles.subTitle, { [styles.center]: !description })}>{subTitle}</div>}
      {!!description && (
        <div className={styles.description}>
          <WhiteSpacePreWrapText>{description}</WhiteSpacePreWrapText>
        </div>
      )}
    </div>
  </div>
);

BorderBox.PlaceHolder = (
  <div className={styles.background}>
    <Icon glyph={SvgNoImage} />
  </div>
);

BorderBox.Image = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const { loading, className, ...rest } = props;
  return <img {...rest} loading={loading ?? "eager"} className={classNames(styles.img, className)} />;
};

export default BorderBox;
