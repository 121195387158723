import classNames from "classnames";

import { FC, useRef } from "react";
import { useParams } from "react-router-dom";
import useI18n from "../../hooks/useTranslations";
import { useSystemsApi } from "../../http/useSystems";
import { getMinutesRoundedTimestamp } from "../../utils";
import CreateConfigurationButton from "../configurations/CreateConfigurationButton";
import SvgNoImage from "../icons/SvgNoImage";
import Header, { HeaderPath } from "../ui/Header";
import Icon from "../ui/Icon";
import ScrollContent from "../ui/ScrollContent";
import WhiteSpacePreWrapText from "../ui/WhiteSpacePreWrapText";
import styles from "./System.module.css";

const System: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const systemsApi = useSystemsApi();

  const aborter = useRef(new AbortController());
  const { data, error, isLoading } = systemsApi.getSystem(id!, aborter.current.signal);

  const path: HeaderPath[] = [{ display: i18n.translation.common.home, url: "/" }];

  if (data?.name) {
    path.push({ display: data.name });
  }

  return (
    <>
      <Header
        title={data?.name ?? i18n.translation.common.loading}
        error={error}
        // TODO add "Edit item" button if the user has admin roles (or edit-item-permission)
        buttons={
          <CreateConfigurationButton
            systemId={id!}
            type="system"
            title={i18n.translation.projects.addTo}
            disabled={!!error || isLoading}
          />
        }
        path={path}
      />
      <ScrollContent padding>
        <div className={styles.container}>
          <div className={styles.imgContainer}>
            <div className={classNames(styles.img, { [styles.background]: !data?.image })}>
              {data?.image ? (
                <img src={`${import.meta.env.VITE_BUCKET_URL}${data.image}?${getMinutesRoundedTimestamp()}`} />
              ) : (
                <Icon glyph={SvgNoImage} />
              )}
            </div>
          </div>
          <WhiteSpacePreWrapText>{data?.descriptionLong}</WhiteSpacePreWrapText>
        </div>
      </ScrollContent>
    </>
  );
};

export default System;
