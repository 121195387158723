import { useEffect, useState } from "react";
import { useAuth } from "../context/useAuthContext";
import de from "../i18n/de";
import en from "../i18n/en";
import es from "../i18n/es";
import fr from "../i18n/fr";
import it from "../i18n/it";
import { LanguageCode } from "../types";

export type Translation = typeof en;
export type TranslationKeys = keyof Translation;

export const ts: { [key in LanguageCode]: Translation } = {
  en,
  it,
  de,
  es,
  fr,
  ar: en,
  cs: en,
  da: en,
  el: en,
  et: en,
  fi: en,
  hr: en,
  hu: en,
  is: en,
  ja: en,
  lt: en,
  lv: en,
  nl: en,
  no: en,
  pl: en,
  pt: en,
  ru: en,
  sk: en,
  sl: en,
  sv: en,
  tr: en,
  zh: en,
  kr: en,
};

export type I18n = {
  translation: Translation;
  errorsMap: Map<number, string>;
};

const useI18n = (): I18n => {
  const auth = useAuth();
  const [translation, setTranslation] = useState<Translation>(ts[auth.identity?.language ?? en.languageCode]);

  useEffect(() => {
    auth.identity?.language && setTranslation(ts[auth.identity.language] ?? en);
  }, [auth.identity?.language]);

  const errorsMap = new Map<number, string>([
    // projects
    [100001, translation.projects.errors.deleteProjectWithConfigurationsNotPossible],
    [100002, translation.projects.errors.notFound],
    [100003, translation.projects.errors.missingName],
    [100004, translation.projects.errors.sharingNotFound],
    [100005, translation.projects.errors.documentNotFound],
    [100006, translation.projects.errors.missingDocumentName],
    [100007, translation.projects.errors.documentAlreadyPresent],
    [100008, translation.projects.errors.missingProjectStatus],
    [100009, translation.projects.errors.deleteProjectWithDocumentsNotPossible],

    // cables
    [200001, translation.cables.errors.notFound],
    [200002, translation.cables.errors.alreadyPresent],
    [200003, translation.cables.errors.noDefined],
    [200004, translation.cables.errors.inUseByConfiguration],

    // users
    [300001, translation.users.errors.notFound],

    // systems
    [400001, translation.systems.errors.notFound],
    [400002, translation.systems.errors.missingName],
    [400003, translation.systems.errors.missingDescriptionShort],
    [400004, translation.systems.errors.missingDescriptionLong],
    [400005, translation.systems.errors.inUseByConfiguration],

    // wallMaterials
    [500001, translation.wallMaterials.errors.notFound],
    [500002, translation.wallMaterials.errors.missingName],
    [500003, translation.wallMaterials.errors.missingDescription],
    [500004, translation.wallMaterials.errors.inUseByConfiguration],

    // configurations
    [600001, translation.configurations.errors.notFound],
    [600002, translation.configurations.errors.missingName],
    [600003, translation.configurations.errors.missingNormative],
    [600004, translation.configurations.errors.missingSteelVersion],
    [600005, translation.configurations.errors.missingUpperSupportInstallationType],
    [600006, translation.configurations.errors.missingUpperSupport],
    [600007, translation.configurations.errors.missingAbsorber],
    [600008, translation.configurations.errors.missingSystemType],
    [600009, translation.configurations.errors.missingCable],

    // systemTypes
    [700001, translation.systemTypes.errors.notFound],
    [700002, translation.systemTypes.errors.missingName],
    [700003, translation.systemTypes.errors.noDefined],
    [700004, translation.systemTypes.errors.missingDescription],
    [700005, translation.systemTypes.errors.inUseByConfiguration],
    [700006, translation.systemTypes.errors.inUseByShuttle],
    [700007, translation.systemTypes.errors.inUseByIntermediateElement],

    // steelVersions
    [800001, translation.steelVersions.errors.notFound],
    [800002, translation.steelVersions.errors.missingName],
    [800003, translation.steelVersions.errors.noDefined],
    [800004, translation.steelVersions.errors.missingDescription],
    [800005, translation.steelVersions.errors.inUseByConfiguration],
    [800006, translation.steelVersions.errors.inUseByUpperExtension],
    [800007, translation.steelVersions.errors.inUseByLowerElement],
    [800008, translation.steelVersions.errors.inUseByIntermediateElement],
    [800009, translation.steelVersions.errors.inUseByAsorber],

    // installationTypes
    [900001, translation.installationTypes.errors.notFound],
    [900002, translation.installationTypes.errors.missingName],
    [900003, translation.installationTypes.errors.noDefined],
    [900004, translation.installationTypes.errors.missingDescription],
    [900005, translation.installationTypes.errors.inUseByConfiguration],
    [900006, translation.installationTypes.errors.inUseByUpperExtension],
    [900007, translation.installationTypes.errors.inUseByLowerElement],
    [900008, translation.installationTypes.errors.inUseByIntermediateElement],
    [900009, translation.installationTypes.errors.inUseByLadderReinforcement],

    // normatives
    [1000001, translation.normatives.errors.notFound],
    [1000002, translation.normatives.errors.missingName],
    [1000003, translation.normatives.errors.noDefined],
    [1000004, translation.normatives.errors.inUseByConfiguration],

    // items
    [1100001, translation.items.errors.notFound],
    [1100002, translation.items.errors.missingName],
    [1100003, translation.items.errors.missingCode],
    [1100004, translation.items.errors.missingDescription],

    // absorbers
    [1200001, translation.absorbers.errors.notFound],
    [1200002, translation.absorbers.errors.alreadyPresent],
    [1200003, translation.absorbers.errors.noDefined],
    [1200004, translation.absorbers.errors.inUseByConfiguration],

    // handles
    [1300001, translation.handles.errors.notFound],
    [1300002, translation.handles.errors.alreadyPresent],
    [1300003, translation.handles.errors.inUseByConfiguration],
    [1300004, translation.handles.errors.inUseByUpperExtension],

    // intermediateElements
    [1400001, translation.intermediateElements.errors.notFound],
    [1400002, translation.intermediateElements.errors.alreadyPresent],
    [1400003, translation.intermediateElements.errors.inUseByConfiguration],

    // ladderReinforcements
    [1500001, translation.ladderReinforcements.errors.notFound],
    [1500002, translation.ladderReinforcements.errors.alreadyPresent],
    [1500003, translation.ladderReinforcements.errors.inUseByConfiguration],

    // lowerElements
    [1600001, translation.lowerElements.errors.notFound],
    [1600002, translation.lowerElements.errors.alreadyPresent],
    [1600003, translation.lowerElements.errors.inUseByConfiguration],

    // shuttles
    [1700001, translation.shuttles.errors.notFound],
    [1700002, translation.shuttles.errors.alreadyPresent],
    [1700003, translation.shuttles.errors.inUseByConfiguration],

    // upperExtensions
    [1800001, translation.upperExtensions.errors.notFound],
    [1800002, translation.upperExtensions.errors.alreadyPresent],
    [1800003, translation.upperExtensions.errors.noDefined],
    [1800004, translation.upperExtensions.errors.inUseByConfiguration],

    // wallMaterialFixings
    [1900001, translation.wallMaterialFixings.errors.notFound],
    [1900002, translation.wallMaterialFixings.errors.missingName],
    [1900003, translation.wallMaterialFixings.errors.missingDescription],
    [1900004, translation.wallMaterialFixings.errors.inUseByConfiguration],
  ]);

  return { translation: translation, errorsMap };
};

export default useI18n;
