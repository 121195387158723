import { FC, useContext, useState } from "react";
import { Controller } from "react-hook-form";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../../../context/notificationContext";
import useI18n from "../../../../../../hooks/useTranslations";
import { AdminInstallationType } from "../../../../../../http/types/vertigrip/installationTypes";
import { LadderReinforcement } from "../../../../../../http/types/vertigrip/ladderReinforcements";
import { useAdminLadderReinforcementsApi } from "../../../../../../http/vertigrip/useLadderReinforcements";
import Button from "../../../../../ui/Button";
import Form from "../../../../../ui/Form";
import FormField from "../../../../../ui/FormField";
import ModalDialog from "../../../../../ui/ModalDialog";
import Numeric from "../../../../../ui/Numeric";
import Switch from "../../../../../ui/Switch";

interface UpdateLadderReinforcementForm {
  installationType: AdminInstallationType | null;
  order: number | null;
  isVisible: boolean;
}

interface EditLadderReinforcementModalDialogProps {
  ladderReinforcement?: LadderReinforcement;
  onClose: () => void;
}

const EditLadderReinforcementModalDialog: FC<EditLadderReinforcementModalDialogProps> = ({
  ladderReinforcement,
  onClose,
}: EditLadderReinforcementModalDialogProps) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const ladderReinforcementApi = useAdminLadderReinforcementsApi();

  return (
    <ModalDialog
      isOpen={!!ladderReinforcement}
      onClose={() => onClose()}
      title={i18n.translation.ladderReinforcements.edit}
    >
      <Form<UpdateLadderReinforcementForm>
        defaultValues={{
          order: ladderReinforcement?.order ?? 50,
          isVisible: ladderReinforcement?.isVisible,
        }}
        submitText={i18n.translation.common.save}
        disabled={isSubmitting}
        secondaryButton={
          <Button buttonProps={{ onClick: () => onClose() }} type="secondary">
            {i18n.translation.common.cancel}
          </Button>
        }
        onSubmit={() => (formData) =>
          ladderReinforcementApi
            .updateAdminLadderReinforcement(ladderReinforcement?.id!, {
              order: formData.order!,
              installationTypeId: ladderReinforcement?.installationType.id!,
              isVisible: formData.isVisible,
            })
            .then(() => dispatch(showSuccessNotification(i18n.translation)))
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            })}
      >
        {({ control, setValue, watch, disabled }) => {
          const visible = !!watch("isVisible");

          return (
            <>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={visible}
                    disabled={disabled}
                    onChange={(checked) => setValue("isVisible", checked)}
                  />
                )}
              </FormField>
              <Controller
                name="order"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.order} error={fieldState.error}>
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        isInvalid={isInvalid}
                        decimalScale={0}
                        allowNegative={false}
                        onBlur={(value) => setValue("order", value)}
                      />
                    )}
                  </FormField>
                )}
              />
            </>
          );
        }}
      </Form>
    </ModalDialog>
  );
};

export default EditLadderReinforcementModalDialog;
