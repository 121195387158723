import { FC, useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../../../../context/notificationContext";
import useI18n from "../../../../../../hooks/useTranslations";
import { useAdminWallMaterialsApi } from "../../../../../../http/vertigrip/useWallMaterials";
import Button from "../../../../../ui/Button";
import Form from "../../../../../ui/Form";
import FormField from "../../../../../ui/FormField";
import FormFieldsContainer from "../../../../../ui/FormFieldsContainer";
import Input from "../../../../../ui/Input";
import ModalDialog from "../../../../../ui/ModalDialog";
import Numeric from "../../../../../ui/Numeric";
import Switch from "../../../../../ui/Switch";

interface CreateWallMaterialForm {
  name: string | null;
  order: number | null;
  isVisible: boolean;
}

const CreateWallMaterialModalDialogButton: FC = () => {
  const { systemId } = useParams<{ systemId: string }>();
  const { id: installationTypeId } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const i18n = useI18n();
  const [showNewItemDialog, setShowNewItemDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const wallMaterialApi = useAdminWallMaterialsApi();

  return (
    <>
      <Button buttonProps={{ onClick: () => setShowNewItemDialog(true) }}>{i18n.translation.common.createNew}</Button>
      <ModalDialog
        isOpen={showNewItemDialog}
        onClose={() => setShowNewItemDialog(false)}
        title={i18n.translation.wallMaterials.createNew}
      >
        <Form<CreateWallMaterialForm>
          defaultValues={{ name: null, order: 50, isVisible: false }}
          submitText={i18n.translation.common.save}
          disabled={isSubmitting}
          secondaryButton={
            <Button buttonProps={{ onClick: () => setShowNewItemDialog(false) }} type="secondary">
              {i18n.translation.common.cancel}
            </Button>
          }
          onSubmit={() => (formData) =>
            wallMaterialApi
              .insertAdminWallMaterial({
                name: formData.name!,
                order: formData.order!,
                installationTypeId: installationTypeId!,
                isVisible: formData.isVisible,
              })
              .then((res) => {
                setIsSubmitting(false);
                navigate(
                  `/admin/systems/${systemId}/installation_types/${installationTypeId}/wall_materials/${res.id}`
                );
              })
              .catch((err) => {
                dispatch(showErrorNotification(i18n, err));
                setIsSubmitting(false);
              })}
        >
          {({ control, setValue, register, formState: { errors }, watch }) => {
            const visible = !!watch("isVisible");

            return (
              <FormFieldsContainer fullWidth>
                <FormField label={i18n.translation.common.name} error={errors.name}>
                  {({ labelId, isInvalid, isOptional }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={isSubmitting}
                      {...register("name", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                    />
                  )}
                </FormField>
                <FormField label={i18n.translation.common.isVisible}>
                  {({ labelId }) => (
                    <Switch id={labelId} checked={visible} onChange={(checked) => setValue("isVisible", checked)} />
                  )}
                </FormField>
                <Controller
                  name="order"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  disabled={isSubmitting}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.order} error={fieldState.error}>
                      {({ labelId, isInvalid }) => (
                        <Numeric
                          {...field}
                          id={labelId}
                          isInvalid={isInvalid}
                          decimalScale={0}
                          allowNegative={false}
                          onBlur={(value) => setValue("order", value)}
                        />
                      )}
                    </FormField>
                  )}
                />
              </FormFieldsContainer>
            );
          }}
        </Form>
      </ModalDialog>
    </>
  );
};

export default CreateWallMaterialModalDialogButton;
