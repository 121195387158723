import { FC } from "react";
import useI18n from "../../../hooks/useTranslations";
import { Text } from "../../../types";
import { getMinutesRoundedTimestamp } from "../../../utils";
import BorderBox from "../../ui/BorderBox";
import ModalDialog from "../../ui/ModalDialog";
import styles from "./SimpleTextModalDialog.module.css";

interface SimpleTextModalDialogProps {
  title: string;
  text: Text;
  isOpen: boolean;
  onClose: () => void;
}

const SimpleTextModalDialog: FC<SimpleTextModalDialogProps> = ({
  title,
  text,
  isOpen,
  onClose,
}: SimpleTextModalDialogProps) => {
  const i18n = useI18n();
  return (
    <ModalDialog
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      actions={[{ title: i18n.translation.common.close, onClick: onClose }]}
      fitContent
    >
      <BorderBox description={text.value} className={styles.textDialog}>
        {text.image ? (
          <div className={styles.img}>
            <BorderBox.Image src={`${import.meta.env.VITE_BUCKET_URL}${text.image}?${getMinutesRoundedTimestamp()}`} />
          </div>
        ) : undefined}
      </BorderBox>
    </ModalDialog>
  );
};

export default SimpleTextModalDialog;
