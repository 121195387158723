import { FC, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../../context/notificationContext";
import useTabs from "../../../hooks/useTabs";
import useI18n from "../../../hooks/useTranslations";
import { UpdateConfigurationBody, VertigripConfiguration } from "../../../http/types/configurations";
import { useConfigurationsApi } from "../../../http/useConfigurations";
import { useVertigripSettings } from "../../../http/vertigrip/useVertigripSettings";
import en from "../../../i18n/en";
import { getUILanguages } from "../../../types";
import Tabs from "../../ui/Tabs";
import VertigripGeneralData, { VertigripGeneralDataFormBody } from "./VertigripGeneralData";
import VertigripIntermediateFixation, { VertigripIntermediateFixationFormBody } from "./VertigripIntermediateFixation";
import VertigripItemsList from "./VertigripItemsList";
import VertigripLowerFixation, { VertigripLowerFixationFormBody } from "./VertigripLowerFixation";
import VertigripUpperFixation, { VertigripUpperFixationFormBody } from "./VertigripUpperFixation";

export interface UpdateVertigripConfigurationFormBody {
  generalData: VertigripGeneralDataFormBody;
  upperFixation: VertigripUpperFixationFormBody;
  lowerFixation: VertigripLowerFixationFormBody;
  intermediateSupport: VertigripIntermediateFixationFormBody;
}

interface VertigripProps {
  configuration: VertigripConfiguration;
  isAdmin?: boolean;
}

const Vertigrip: FC<VertigripProps> = ({ configuration, isAdmin }: VertigripProps) => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const configurationsApi = useConfigurationsApi();
  const { id } = useParams<{ id: string }>();

  const languages = getUILanguages(i18n.translation);

  const aborter = useRef(new AbortController());
  const { isLoading, data: settings } = useVertigripSettings(aborter.current.signal, id);

  const normative =
    settings?.normatives.find((x) => x.id == configuration.data?.normativeId) ?? settings?.normatives[0];

  const steelVersion =
    settings?.steelVersions.find((x) => x.id === configuration.data?.steelVersionId) ?? settings?.steelVersions[0];

  const cable = settings?.cables.find((x) => x.id == configuration.data?.cableId) ?? settings?.cables[0];

  const upperFixationinstallationType = steelVersion?.installationTypes.find(
    (x) => x.id === configuration.data?.upperFixationInstallationTypeId
  );

  const upperExtension = upperFixationinstallationType?.upperExtensions.find(
    (x) => x.id === configuration.data?.upperExtensionId
  );

  const upperExtensionWallMaterial = upperFixationinstallationType?.wallMaterials.find(
    (x) => x.id === configuration.data?.upperExtensionWallMaterialId
  );

  const upperExtensionWallMaterialFixing = upperExtensionWallMaterial?.fixings.find(
    (x) => x.id === configuration.data?.upperExtensionWallMaterialFixingId
  );

  const handle = upperExtension?.handles.find((x) => x.id === configuration.data?.handleId);

  const ladderReinforcement = upperFixationinstallationType?.ladderReinforcements.find(
    (x) => x.id === configuration.data?.ladderReinforcementId
  );

  const lowerFixationinstallationType = steelVersion?.installationTypes.find(
    (x) => x.id === configuration.data?.lowerFixationInstallationTypeId
  );

  const lowerElement = lowerFixationinstallationType?.lowerElements.find(
    (x) => x.id === configuration.data?.lowerElementId
  );

  const lowerElementWallMaterial = lowerFixationinstallationType?.wallMaterials.find(
    (x) => x.id === configuration.data?.lowerElementWallMaterialId
  );

  const lowerElementWallMaterialFixing = lowerElementWallMaterial?.fixings.find(
    (x) => x.id === configuration.data?.lowerElementWallMaterialFixingId
  );

  const absorber = steelVersion?.absorbers.find((x) => x.id === configuration.data?.absorberId);

  const systemType = settings?.systemTypes.find((x) => x.id === configuration.data?.systemTypeId);

  const shuttle = systemType?.shuttles.find((x) => x.id === configuration.data?.shuttleId);

  const intermediateSupportInstallationType = steelVersion?.installationTypes.find(
    (x) => x.id === configuration.data?.intermediateSupportInstallationTypeId
  );

  const intermediateElement = intermediateSupportInstallationType?.intermediateElements[0]
    ? intermediateSupportInstallationType?.intermediateElements?.find(
        (x) => x.id === configuration.data?.intermediateElementId
      )
    : systemType?.intermediateElements
        ?.find(
          (x) =>
            x.fixed === configuration.data?.fixedIntermediateFixationInstallationType &&
            steelVersion?.id === x.steelVersionId
        )
        ?.items?.find((x) => x.id == configuration.data?.intermediateElementId);

  const intermediateElementWallMaterial = intermediateSupportInstallationType?.wallMaterials.find(
    (x) => x.id === configuration.data?.intermediateElementWallMaterialId
  );

  const intermediateElementWallMaterialFixing = intermediateElementWallMaterial?.fixings.find(
    (x) => x.id === configuration.data?.intermediateElementWallMaterialFixingId
  );

  const [defaultValues, setDefaultValues] = useState<UpdateVertigripConfigurationFormBody>();

  useEffect(() => {
    if (settings) {
      setDefaultValues({
        generalData: {
          name: configuration.name,
          language: languages.find((x) => x.value == configuration?.language) ?? languages[0]!,
          description: configuration.description ?? null,
          heightToReach: configuration.data?.heightToReach ?? null,
          normative: normative ?? null,
          steelVersion: steelVersion ?? null,
          cable: cable ?? null,
        },
        upperFixation: {
          handle: handle ?? null,
          ladderReinforcement: ladderReinforcement ?? null,
          installationType: upperFixationinstallationType ?? null,
          upperExtension: upperExtension ?? null,
          wallMaterial: upperExtensionWallMaterial ?? null,
          fixing: upperExtensionWallMaterialFixing ?? null,
        },
        lowerFixation: {
          baseHeight: { value: configuration.data?.baseHeight ?? 0.3 },
          installationType: lowerFixationinstallationType ?? null,
          lowerElement: lowerElement ?? null,
          wallMaterial: lowerElementWallMaterial ?? null,
          fixing: lowerElementWallMaterialFixing ?? null,
          noWallMaterial: configuration.data?.noLowerElementWallMaterial ?? false,
          absorber: absorber ?? null,
        },
        intermediateSupport: {
          intermediates: configuration.data?.intermediates ?? null,
          systemType: systemType ?? null,
          shuttle: shuttle ?? null,
          intermediateElement: intermediateElement ?? null,
          wallMaterial: intermediateElementWallMaterial ?? null,
          fixing: intermediateElementWallMaterialFixing ?? null,
          noWallMaterial: configuration.data?.noIntermediateElementWallMaterial ?? false,
          fixedInstallationType: configuration.data?.fixedIntermediateFixationInstallationType ?? null,
          installationType: intermediateSupportInstallationType ?? null,
        },
      });
    }
  }, [settings]);

  const submit = async (formBody: UpdateVertigripConfigurationFormBody): Promise<void> => {
    const body: UpdateConfigurationBody = {
      name: formBody.generalData.name,
      description: formBody.generalData.description ?? null,
      language: formBody.generalData.language?.value!,
      vertigrip: {
        heightToReach: formBody.generalData.heightToReach,
        normativeId: formBody.generalData.normative?.id!,
        steelVersionId: formBody.generalData.steelVersion?.id!,
        cableId: formBody.generalData.cable?.id!,
        upperFixationInstallationTypeId: formBody.upperFixation.installationType?.id!,
        upperExtensionId: formBody.upperFixation.upperExtension?.id!,
        upperExtensionWallMaterialId:
          formBody.upperFixation.wallMaterial?.id !== "fakeId" ? formBody.upperFixation.wallMaterial?.id : undefined,
        upperExtensionWallMaterialFixingId: formBody.upperFixation.fixing?.id,
        handleId: formBody.upperFixation.handle?.id,
        ladderReinforcementId: formBody.upperFixation.ladderReinforcement?.id,
        baseHeight: formBody.lowerFixation.baseHeight?.value,
        lowerFixationInstallationTypeId: formBody.lowerFixation.installationType?.id,
        lowerElementId: formBody.lowerFixation.lowerElement?.id,
        lowerElementWallMaterialId:
          formBody.lowerFixation.wallMaterial?.id !== "fakeId" ? formBody.lowerFixation.wallMaterial?.id : undefined,
        lowerElementWallMaterialFixingId: formBody.lowerFixation.fixing?.id,
        noLowerElementWallMaterial: formBody.lowerFixation.noWallMaterial,
        absorberId: formBody.lowerFixation.absorber?.id!,
        intermediates: formBody.intermediateSupport.intermediates,
        systemTypeId: formBody.intermediateSupport.systemType?.id!,
        shuttleId: formBody.intermediateSupport.shuttle?.id,
        fixedIntermediateFixationInstallationType: formBody.intermediateSupport.fixedInstallationType,
        intermediateSupportInstallationTypeId: formBody.intermediateSupport.installationType?.id,
        intermediateElementId: formBody.intermediateSupport.intermediateElement?.id,
        intermediateElementWallMaterialId:
          formBody.intermediateSupport.wallMaterial?.id !== "fakeId"
            ? formBody.intermediateSupport.wallMaterial?.id
            : undefined,
        intermediateElementWallMaterialFixingId: formBody.intermediateSupport.fixing?.id,
        noIntermediateElementWallMaterial: formBody.intermediateSupport.noWallMaterial,
      },
    };

    const optimisticData: VertigripConfiguration = {
      ...configuration,
      ...body,
      description: formBody.generalData.description ?? undefined,
      data: {
        ...body.vertigrip,
        noLowerElementWallMaterial: formBody.lowerFixation.noWallMaterial,
        fixedIntermediateFixationInstallationType: formBody.intermediateSupport.fixedInstallationType ?? undefined,
        noIntermediateElementWallMaterial: formBody.intermediateSupport.noWallMaterial,
      },
    };

    try {
      const res = await configurationsApi.updateConfiguration(
        configuration.project.id,
        configuration.id,
        body,
        optimisticData
      );
      setDefaultValues(formBody);
      !!isAdmin && dispatch(showSuccessNotification(i18n.translation));
      return res;
    } catch (err) {
      return dispatch(showErrorNotification(i18n, err));
    }
  };

  const controller = useTabs(5);

  const isGeneralDataValid = (state: UpdateVertigripConfigurationFormBody) =>
    state.generalData.heightToReach! > 0 && !!state.generalData.normative && !!state.generalData.steelVersion;

  const isUpperFixationValid = (state: UpdateVertigripConfigurationFormBody) =>
    isGeneralDataValid(state) && !!state.upperFixation.installationType && !!state.upperFixation.upperExtension;

  const isLowerFixationValid = (state: UpdateVertigripConfigurationFormBody) =>
    isUpperFixationValid(state) &&
    state.lowerFixation.baseHeight?.value &&
    state.lowerFixation.baseHeight?.value > 0 &&
    !!state.lowerFixation.installationType &&
    !!state.lowerFixation.lowerElement &&
    !!state.lowerFixation.absorber;

  const isIntermediateFixationValid = (state: UpdateVertigripConfigurationFormBody) =>
    isLowerFixationValid(state) &&
    state.intermediateSupport.intermediates! >= 0 &&
    !!state.intermediateSupport.intermediateElement;

  const onLowerFixationClick = () => {
    if (defaultValues) {
      // create the body for the update
      const body: UpdateVertigripConfigurationFormBody = {
        ...defaultValues,
        lowerFixation: defaultValues.lowerFixation,
      };

      let update = false;

      // set default installation type
      if (defaultValues.upperFixation.installationType && !defaultValues.lowerFixation.installationType) {
        body.lowerFixation.installationType = defaultValues.upperFixation.installationType;
        body.lowerFixation.lowerElement = defaultValues.upperFixation.installationType.lowerElements[0] ?? null;
        update = true;
      }

      // set default wall material
      if (
        !defaultValues.lowerFixation.noWallMaterial &&
        defaultValues.upperFixation.wallMaterial &&
        !defaultValues.lowerFixation.wallMaterial
      ) {
        body.lowerFixation.wallMaterial =
          defaultValues.lowerFixation.installationType?.wallMaterials.find(
            (x) => x.id === defaultValues.upperFixation.wallMaterial?.id
          ) ?? null;

        // set default wall material fixings
        if (body.lowerFixation.wallMaterial) {
          defaultValues.lowerFixation.fixing =
            body.lowerFixation.wallMaterial.fixings.find((x) => x.id === defaultValues.upperFixation.fixing?.id) ??
            null;
        }
        update = true;
      }

      // if it has a change, submit the body
      if (update) {
        submit(body);
      }
    }
  };

  const onIntermediateFixationClick = () => {
    if (defaultValues) {
      // create the body for the update
      const body: UpdateVertigripConfigurationFormBody = {
        ...defaultValues,
        intermediateSupport: defaultValues.intermediateSupport,
      };

      let update = false;

      if (defaultValues.lowerFixation.installationType && !defaultValues.intermediateSupport.installationType) {
        // set default installation type
        body.intermediateSupport.installationType = defaultValues.lowerFixation.installationType;
        body.intermediateSupport.intermediateElement =
          defaultValues.lowerFixation.installationType.intermediateElements[0] ?? null;
        update = true;
      }

      // set default wall material
      if (
        !defaultValues.intermediateSupport.noWallMaterial &&
        defaultValues.lowerFixation.wallMaterial &&
        !defaultValues.intermediateSupport.wallMaterial
      ) {
        body.intermediateSupport.wallMaterial =
          defaultValues.intermediateSupport.installationType?.wallMaterials.find(
            (x) => x.id === defaultValues.lowerFixation.wallMaterial?.id
          ) ?? null;

        // set default wall material fixings
        if (body.intermediateSupport.wallMaterial) {
          defaultValues.intermediateSupport.fixing =
            body.intermediateSupport.wallMaterial.fixings.find(
              (x) => x.id === defaultValues.lowerFixation.fixing?.id
            ) ?? null;
        }
        update = true;
      }

      // if it has a change, submit the body
      if (update) {
        submit(body);
      }
    }
  };

  const tabs = [
    { title: i18n.translation.common.generalData, onClick: () => controller.setSelectedIndex(0) },
    {
      title: i18n.translation.common.upperSupport,
      onClick: () => controller.setSelectedIndex(1),
      disabled: !defaultValues || !isGeneralDataValid(defaultValues),
    },
    {
      title: i18n.translation.common.lowerSupport,
      onClick: () => {
        controller.setSelectedIndex(2);
        onLowerFixationClick();
      },
      disabled: !defaultValues || !isUpperFixationValid(defaultValues),
    },
    {
      title: i18n.translation.common.intermediateSupport,
      onClick: () => {
        controller.setSelectedIndex(3);
        onIntermediateFixationClick();
      },
      disabled: !defaultValues || !isLowerFixationValid(defaultValues),
    },
    {
      title: i18n.translation.items.list,
      onClick: () => controller.setSelectedIndex(4),
      disabled: !defaultValues || !isIntermediateFixationValid(defaultValues),
    },
  ];

  useEffect(() => {
    if (defaultValues) {
      const index =
        !isIntermediateFixationValid(defaultValues) && controller.selectedIndex > 3
          ? 3
          : !isLowerFixationValid(defaultValues) && controller.selectedIndex > 2
          ? 2
          : !isUpperFixationValid(defaultValues) && controller.selectedIndex > 1
          ? 1
          : !isGeneralDataValid(defaultValues) && controller.selectedIndex > 0
          ? 0
          : 4;
      controller.selectedIndex > index && controller.setSelectedIndex(index);
    }
  }, [defaultValues]);

  const reset = () =>
    submit({
      generalData: {
        cable: settings?.cables[0] ?? null,
        description: null,
        language: languages.find((x) => x.value === en.languageCode) ?? null,
        name: configuration.name,
        heightToReach: null,
        normative: settings?.normatives[0] ?? null,
        steelVersion: settings?.steelVersions[0] ?? null,
      },
      upperFixation: {
        installationType: settings?.steelVersions[0]?.installationTypes[0] ?? null,
        upperExtension: settings?.steelVersions[0]?.installationTypes[0]?.upperExtensions[0] ?? null,
        handle: null,
        ladderReinforcement: null,
        wallMaterial: null,
        fixing: null,
      },
      intermediateSupport: {
        intermediates: null,
        systemType: settings?.systemTypes[0] ?? null,
        shuttle: null,
        fixedInstallationType: null,
        installationType: null,
        intermediateElement: null,
        wallMaterial: null,
        noWallMaterial: false,
        fixing: null,
      },
      lowerFixation: {
        baseHeight: {
          value: 0.3,
        },
        installationType: null,
        lowerElement: null,
        wallMaterial: null,
        noWallMaterial: false,
        fixing: null,
        absorber: settings?.steelVersions[0]?.absorbers[0] ?? null,
      },
    });

  return (
    <Tabs
      controller={controller}
      tabs={tabs}
      panels={[
        <VertigripGeneralData
          isAdmin={isAdmin}
          defaultValues={defaultValues?.generalData}
          settings={settings}
          isLoading={isLoading}
          canEdit={configuration.canEdit}
          next={controller.next}
          reset={reset}
          onSubmit={(generalData) => {
            if (defaultValues) {
              // create the body for the update
              const body: UpdateVertigripConfigurationFormBody = {
                ...defaultValues,
                generalData: generalData,
                intermediateSupport: { ...defaultValues.intermediateSupport },
              };

              // if the installation of the upper fixation is set, update it based on the current steel version
              if (body.upperFixation.installationType) {
                const installationType =
                  body.generalData.steelVersion?.installationTypes.find(
                    (x) => x.id == body.upperFixation.installationType?.id
                  ) ?? null;

                body.upperFixation.installationType = installationType;
                body.upperFixation.upperExtension = installationType?.upperExtensions[0] ?? null;
              }

              // set the absorber
              body.lowerFixation.absorber = body.generalData.steelVersion?.absorbers[0] ?? null;

              // if the installation of the lower fixation is set, update it and set the lower element, based on the current steel version
              if (body.lowerFixation.installationType) {
                const installationType =
                  body.generalData.steelVersion?.installationTypes.find(
                    (x) => x.id == body.lowerFixation.installationType?.id
                  ) ?? null;
                body.lowerFixation.installationType = installationType;

                if (body.lowerFixation.lowerElement) {
                  body.lowerFixation.lowerElement = installationType?.lowerElements[0] ?? null;
                }
              }

              // if the installation of the intermediate fixation is set, update it and set the intermediate element, based on the current steel version
              if (body.intermediateSupport.installationType) {
                const installationType =
                  body.generalData.steelVersion?.installationTypes.find(
                    (x) => x.id == body.intermediateSupport.installationType?.id
                  ) ?? null;
                body.intermediateSupport.installationType = installationType;

                if (body.intermediateSupport.intermediateElement) {
                  body.intermediateSupport.intermediateElement = installationType?.intermediateElements[0] ?? null;
                }
              }

              // compute the intermediated
              body.intermediateSupport.intermediates = computeIntermediates(body);

              return submit(body);
            }
            return undefined;
          }}
        />,
        <VertigripUpperFixation
          isAdmin={isAdmin}
          defaultValues={defaultValues?.upperFixation}
          steelVersion={defaultValues?.generalData.steelVersion}
          isLoading={isLoading}
          canEdit={configuration.canEdit}
          next={() => {
            onLowerFixationClick();
            controller.next();
          }}
          back={controller.previous}
          onSubmit={(upperFixation) => {
            if (defaultValues) {
              // create the body for the update
              const body: UpdateVertigripConfigurationFormBody = {
                ...defaultValues,
                upperFixation: upperFixation,
              };

              return submit(body);
            }
            return undefined;
          }}
        />,
        <VertigripLowerFixation
          isAdmin={isAdmin}
          defaultValues={defaultValues?.lowerFixation}
          steelVersion={defaultValues?.generalData.steelVersion}
          settings={settings}
          isLoading={isLoading}
          canEdit={configuration.canEdit}
          next={() => {
            onIntermediateFixationClick();
            controller.next();
          }}
          back={controller.previous}
          onSubmit={(lowerFixation) => {
            if (defaultValues) {
              // create the body for the update
              const body: UpdateVertigripConfigurationFormBody = {
                ...defaultValues,
                lowerFixation: lowerFixation,
                intermediateSupport: { ...defaultValues.intermediateSupport },
              };
              // compute the intermediated
              body.intermediateSupport.intermediates = computeIntermediates(body);

              return submit(body);
            }
            return undefined;
          }}
        />,
        <VertigripIntermediateFixation
          isAdmin={isAdmin}
          totalHeight={defaultValues ? computeHeight(defaultValues) : 0}
          defaultValues={defaultValues?.intermediateSupport}
          steelVersion={defaultValues?.generalData.steelVersion}
          defaultInstallationType={defaultValues?.lowerFixation.installationType}
          settings={settings}
          isLoading={isLoading}
          canEdit={configuration.canEdit}
          next={controller.next}
          back={controller.previous}
          onSubmit={(intermediateSupport) => {
            if (defaultValues) {
              // create the body for the update
              const body: UpdateVertigripConfigurationFormBody = {
                ...defaultValues,
                intermediateSupport: intermediateSupport,
              };

              return submit(body);
            }
            return undefined;
          }}
        />,
        <VertigripItemsList configurationId={configuration.id} back={controller.previous} />,
      ]}
    />
  );
};

export default Vertigrip;

export const computeHeight = (body: UpdateVertigripConfigurationFormBody) => {
  const value = (body.generalData.heightToReach ?? 0) - (body.lowerFixation.baseHeight?.value ?? 0);
  return value < 0 ? 0 : value;
};

export const computeIntermediates = (body: UpdateVertigripConfigurationFormBody) => {
  const value = Math.ceil(computeHeight(body) / 5) - 1;
  return value < 0 ? 0 : value;
};
