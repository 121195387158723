import { FC, useContext, useState } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import { InsertProjectSharingBody } from "../../http/types/projectSharings";
import { useProjectSharingsApi } from "../../http/useProjectSharings";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Input from "../ui/Input";
import ModalDialog from "../ui/ModalDialog";
import Switch from "../ui/Switch";

interface ShareProjectForm {
  userEmail: string | null;
  edit: boolean;
}

interface ShareProjectButtonProps {
  projectId: string;
  isAdmin?: boolean;
  isDisabled?: boolean;
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ShareProjectButton: FC<ShareProjectButtonProps> = ({
  projectId,
  isAdmin,
  isDisabled,
}: ShareProjectButtonProps) => {
  const i18n = useI18n();
  const [isModalOpen, setModalOpen] = useState(false);
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const projectSharingsApi = useProjectSharingsApi();

  const onSubmit = (formData: ShareProjectForm) => {
    setIsSubmitting(true);
    const body: InsertProjectSharingBody = {
      userEmail: formData.userEmail ?? "",
      edit: formData.edit,
    };
    projectSharingsApi
      .insertProjectSharing(projectId, body, isAdmin)
      .then(() => {
        setModalOpen(false);
        setIsSubmitting(false);
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <ModalDialog
        title={i18n.translation.projects.shareWithUser}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Form<ShareProjectForm>
          defaultValues={{
            userEmail: null,
            edit: false,
          }}
          submitText={i18n.translation.common.add}
          disabled={isSubmitting}
          secondaryButton={
            <Button
              buttonProps={{
                disabled: isDisabled,
                onClick: () => setModalOpen(false),
              }}
              type="secondary"
            >
              {i18n.translation.common.cancel}
            </Button>
          }
          onSubmit={() => onSubmit}
        >
          {({ register, formState: { errors }, setValue, watch }) => {
            const edit = watch("edit");

            return (
              <FormFieldsContainer fullWidth>
                <FormField label={i18n.translation.users.usersEmail} error={errors.userEmail}>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      autoComplete="on"
                      {...register("userEmail", {
                        required: !isOptional,
                        pattern: {
                          value: emailRegex,
                          message: i18n.translation.common.fieldInvalidValue,
                        },
                        minLength: 1,
                      })}
                    />
                  )}
                </FormField>
                <FormField label={"Write permission"}>
                  {({ labelId }) => (
                    <Switch id={labelId} checked={edit} onChange={(checked) => setValue("edit", checked)} />
                  )}
                </FormField>
              </FormFieldsContainer>
            );
          }}
        </Form>
      </ModalDialog>
      <Button
        glyph={SvgAdd}
        buttonProps={{
          onClick: () => {
            setModalOpen(true);
          },
        }}
      >
        {i18n.translation.users.add}
      </Button>
    </>
  );
};

export default ShareProjectButton;
