import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import useI18n from "../../../hooks/useTranslations";
import {
  HandleForSettings,
  InstallationTypeForSettings,
  LadderReinforcementForSettings,
  SteelVersionForSettings,
  UpperExtensionForSettings,
  WallMaterialFixingForSettings,
  WallMaterialForSettings,
} from "../../../http/types/vertigrip/vertigripSettings";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import { ItemsHelpModal } from "../../ui/ItemsHelpModal";
import LoadingSpinner from "../../ui/LoadingSpinner";
import reactSelectStyles from "../../ui/ReactSelectUtils";
import ScrollContent from "../../ui/ScrollContent";
import styles from "./VertigripUpperFixation.module.css";
import { WallMaterialFixingsHelpModal } from "./WallMaterialFixingsHelpModal";
import { WallMaterialsHelpModal } from "./WallMaterialsHelpModal";

export interface VertigripUpperFixationFormBody {
  installationType: InstallationTypeForSettings | null;
  upperExtension: UpperExtensionForSettings | null;
  handle: HandleForSettings | null;
  ladderReinforcement: LadderReinforcementForSettings | null;
  wallMaterial: WallMaterialForSettings | null;
  fixing: WallMaterialFixingForSettings | null;
}

interface VertigripUpperFixationProps {
  defaultValues?: VertigripUpperFixationFormBody;
  steelVersion?: SteelVersionForSettings | null;
  isLoading?: boolean;
  back: () => void;
  next: () => void;
  onSubmit: (body: VertigripUpperFixationFormBody) => Promise<void> | undefined;
  isAdmin?: boolean;
  canEdit?: boolean;
}

const VertigripUpperFixation: FC<VertigripUpperFixationProps> = ({
  defaultValues,
  steelVersion,
  isLoading,
  back,
  next,
  onSubmit,
  isAdmin,
  canEdit,
}: VertigripUpperFixationProps) => {
  const i18n = useI18n();
  const [showInstallationTypesHelpModal, setShowInstallationTypesHelpModal] = useState(false);
  const [showUpperExtensionsHelpModal, setShowUpperExtensionsHelpModal] = useState(false);
  const [showHandlesHelpModal, setShowHandlesHelpModal] = useState(false);
  const [showLadderReinforcementsHelpModal, setShowLadderReinforcementsHelpModal] = useState(false);
  const [showWallMaterialsHelpModal, setShowWallMaterialsHelpModal] = useState(false);
  const [showWallMaterialFixingsHelpModal, setShowWallMaterialFixingsHelpModal] = useState(false);

  const fakeHandle: HandleForSettings = {
    id: "fakeId",
    code: i18n.translation.common.no,
    description: "",
  };

  const fakeLadderReinforcement: LadderReinforcementForSettings = {
    id: "fakeId",
    code: i18n.translation.common.no,
    description: "",
  };

  const fakeWallMaterial: WallMaterialForSettings = {
    id: "fakeId",
    name: i18n.translation.wallMaterials.noWallMaterial,
    description: "",
    fixings: [],
  };

  return (
    <ScrollContent direction="row">
      <Form<VertigripUpperFixationFormBody>
        mode={"onBlur"}
        defaultValues={defaultValues}
        disabled={isLoading}
        onSubmit={() => (formData) =>
          // the admin page has a proper save button, automatic save is disabled
          // if user cannot edit, do not save, just move to next.
          isAdmin ? onSubmit(formData) : canEdit ? onSubmit(formData)?.then(() => next()) : next()}
        submitText={isAdmin ? i18n.translation.common.save : i18n.translation.common.next}
        secondaryButton={
          <>
            {isLoading && <LoadingSpinner delayed={0} size="small" />}
            {!isAdmin ? (
              <Button
                buttonProps={{
                  disabled: isLoading,
                  onClick: back,
                }}
                type="secondary"
              >
                {i18n.translation.common.back}
              </Button>
            ) : undefined}
          </>
        }
      >
        {({ setValue, watch, control, handleSubmit, reset }) => {
          // if it is not an admin page and the user canEdit, save end set the updated data
          // !isAdmin: because the automatic save is disabled, admins have the proper save button
          const submit = () =>
            !isAdmin &&
            canEdit &&
            handleSubmit((data) => {
              onSubmit(data)?.then(() => {
                reset(data);
              });
            })();

          useEffect(() => {
            defaultValues && reset(defaultValues);
          }, [defaultValues]);

          const upperExtension = watch("upperExtension");
          const installationType = watch("installationType");
          const wallMaterial = watch("wallMaterial");

          return (
            <div className={styles.form}>
              <Controller
                name="installationType"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.installationType ?? null}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.installationTypes.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowInstallationTypesHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateInstallationType = (
                        value?: InstallationTypeForSettings | SingleValue<InstallationTypeForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          const installationType =
                            steelVersion?.installationTypes.find((x) => x.id == value?.id) ?? null;
                          setValue("installationType", installationType);

                          const upperExtension = installationType?.upperExtensions[0];
                          setValue("upperExtension", upperExtension ?? null);

                          !upperExtension?.handles[0] && setValue("handle", null);
                          !value?.ladderReinforcements[0] && setValue("ladderReinforcement", null);

                          setValue("wallMaterial", null);
                          setValue("fixing", null);

                          submit();
                        }
                      };

                      const installationTypes = steelVersion?.installationTypes ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={installationTypes}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<InstallationTypeForSettings>(fieldState.invalid)}
                            onChange={updateInstallationType}
                          />
                          {!!showInstallationTypesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.installationTypes.plural}
                              onClose={() => setShowInstallationTypesHelpModal(false)}
                              isOpen={showInstallationTypesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (installationType) => {
                                      updateInstallationType(
                                        installationTypes.find((x) => x.id == installationType.id)
                                      );
                                      setShowInstallationTypesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={installationTypes.map((x) => ({
                                id: x.id,
                                title: x.name,
                                description: x.description,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="upperExtension"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.upperExtensions.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowUpperExtensionsHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateUpperExtension = (
                        value?: UpperExtensionForSettings | SingleValue<UpperExtensionForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("upperExtension", value ?? null);
                          !value?.handles[0] && setValue("handle", null);

                          submit();
                        }
                      };

                      const upperExtensions = watch("installationType")?.upperExtensions ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            options={upperExtensions}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.code}
                            styles={reactSelectStyles<UpperExtensionForSettings>(fieldState.invalid)}
                            onChange={updateUpperExtension}
                          />
                          {!!showUpperExtensionsHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.upperExtensions.plural}
                              onClose={() => setShowUpperExtensionsHelpModal(false)}
                              isOpen={showUpperExtensionsHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (upperExtension) => {
                                      updateUpperExtension(upperExtensions.find((x) => x.id == upperExtension.id));
                                      setShowUpperExtensionsHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={upperExtensions.map((x) => ({
                                id: x.id,
                                title: x.code,
                                description: x.description,
                                name: x.name,
                                image: x.image,
                              }))}
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="handle"
                control={control}
                defaultValue={defaultValues?.handle ?? fakeHandle}
                disabled={!upperExtension?.handles[0]}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.handles.singular}
                    error={fieldState.error}
                    showHelp={
                      upperExtension?.handles[0]
                        ? { onOpen: isLoading ? undefined : () => setShowHandlesHelpModal(true) }
                        : undefined
                    }
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateHandle = (value?: HandleForSettings | SingleValue<HandleForSettings>) => {
                        if (value?.id !== field.value?.id) {
                          setValue("handle", value?.id == fakeHandle.id ? null : value ?? null);

                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={[...(upperExtension?.handles ?? []), fakeHandle]}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) =>
                              x.id === fakeHandle.id ? i18n.translation.handles.noHandle : x.code
                            }
                            styles={reactSelectStyles<HandleForSettings>(fieldState.invalid)}
                            onChange={updateHandle}
                            value={defaultValues?.handle ?? fakeHandle}
                          />
                          {!!showHandlesHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.handles.plural}
                              onClose={() => setShowHandlesHelpModal(false)}
                              isOpen={showHandlesHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (selectedOption) => {
                                      updateHandle(upperExtension?.handles.find((x) => x.id == selectedOption.id));
                                      setShowHandlesHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={
                                upperExtension?.handles.map((x) => ({
                                  id: x.id,
                                  title: x.code,
                                  description: x.description,
                                  name: x.name,
                                  image: x.image,
                                })) ?? []
                              }
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="ladderReinforcement"
                control={control}
                defaultValue={defaultValues?.ladderReinforcement ?? fakeLadderReinforcement}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.ladderReinforcements.singular}
                    hidden={!installationType?.ladderReinforcements[0]}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowLadderReinforcementsHelpModal(true) }}
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateLadderReinforcements = (
                        value?: LadderReinforcementForSettings | SingleValue<LadderReinforcementForSettings>
                      ) => {
                        if (value?.id !== (field.value ?? fakeLadderReinforcement).id) {
                          setValue(
                            "ladderReinforcement",
                            value?.id == fakeLadderReinforcement.id ? null : value ?? null
                          );

                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={[
                              ...(defaultValues?.installationType?.ladderReinforcements ?? []),
                              fakeLadderReinforcement,
                            ]}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) =>
                              x.id === fakeLadderReinforcement.id
                                ? i18n.translation.ladderReinforcements.noLadderReinforcement
                                : x.code
                            }
                            styles={reactSelectStyles<LadderReinforcementForSettings>(fieldState.invalid)}
                            onChange={updateLadderReinforcements}
                            value={defaultValues?.ladderReinforcement ?? fakeLadderReinforcement}
                          />
                          {!!showLadderReinforcementsHelpModal && (
                            <ItemsHelpModal
                              title={i18n.translation.ladderReinforcements.plural}
                              onClose={() => setShowLadderReinforcementsHelpModal(false)}
                              isOpen={showLadderReinforcementsHelpModal}
                              onSelect={
                                isAdmin || canEdit
                                  ? (selectedOption) => {
                                      updateLadderReinforcements(
                                        installationType?.ladderReinforcements.find((x) => x.id == selectedOption.id)
                                      );
                                      setShowLadderReinforcementsHelpModal(false);
                                    }
                                  : undefined
                              }
                              items={
                                installationType?.ladderReinforcements.map((x) => ({
                                  id: x.id,
                                  title: x.code,
                                  description: x.description,
                                  name: x.name,
                                  image: x.image,
                                })) ?? []
                              }
                            />
                          )}
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="wallMaterial"
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterials.singular}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={{ onOpen: isLoading ? undefined : () => setShowWallMaterialsHelpModal(true) }}
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateWallMaterial = (
                        value?: WallMaterialForSettings | SingleValue<WallMaterialForSettings>
                      ) => {
                        if (value?.id !== (field.value ?? fakeWallMaterial).id) {
                          const wm = value?.id == fakeWallMaterial.id ? null : value ?? null;
                          setValue("wallMaterial", wm);
                          setValue("fixing", wm?.fixings[0] ?? null);

                          submit();
                        }
                      };

                      const wallMaterials = installationType?.wallMaterials ?? [];

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={[fakeWallMaterial, ...wallMaterials]}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterial}
                            value={defaultValues?.wallMaterial ?? fakeWallMaterial}
                          />
                          <WallMaterialsHelpModal
                            onClose={() => setShowWallMaterialsHelpModal(false)}
                            isOpen={showWallMaterialsHelpModal}
                            onSelect={
                              isAdmin || canEdit
                                ? (selectedOption) => {
                                    updateWallMaterial(wallMaterials.find((x) => x.id == selectedOption.id));
                                    setShowWallMaterialsHelpModal(false);
                                  }
                                : undefined
                            }
                            items={wallMaterials}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="fixing"
                control={control}
                defaultValue={defaultValues?.fixing}
                disabled={!wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterialFixings.singular}
                    disabled={field.disabled}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={
                      !wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id
                        ? undefined
                        : { onOpen: isLoading ? undefined : () => setShowWallMaterialFixingsHelpModal(true) }
                    }
                  >
                    {({ labelId }) => {
                      const updateWallMaterialFixing = (
                        value?: WallMaterialFixingForSettings | SingleValue<WallMaterialFixingForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("fixing", value ?? null);

                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading || !canEdit}
                            isLoading={isLoading}
                            options={wallMaterial?.fixings ?? []}
                            placeholder={`${i18n.translation.common.select}...`}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialFixingForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterialFixing}
                          />
                          <WallMaterialFixingsHelpModal
                            onClose={() => setShowWallMaterialFixingsHelpModal(false)}
                            isOpen={showWallMaterialFixingsHelpModal}
                            onSelect={
                              isAdmin || canEdit
                                ? (selectedOption) => {
                                    updateWallMaterialFixing(
                                      wallMaterial?.fixings?.find((x) => x.id == selectedOption.id)
                                    );
                                    setShowWallMaterialFixingsHelpModal(false);
                                  }
                                : undefined
                            }
                            items={wallMaterial?.fixings ?? []}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
            </div>
          );
        }}
      </Form>
    </ScrollContent>
  );
};

export default VertigripUpperFixation;
