import { FC, Fragment } from "react";
import useI18n from "../../../hooks/useTranslations";
import { WallMaterialFixingForSettings } from "../../../http/types/vertigrip/vertigripSettings";
import { getMinutesRoundedTimestamp } from "../../../utils";
import BorderBox from "../../ui/BorderBox";
import ModalDialog from "../../ui/ModalDialog";
import styles from "./WallMaterialsHelpModal.module.css";

type WallMaterialsHelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (item: WallMaterialFixingForSettings) => void;
  items: WallMaterialFixingForSettings[];
};

export const WallMaterialsHelpModal: FC<WallMaterialsHelpModalProps> = ({
  items,
  isOpen,
  onClose,
  onSelect,
}: WallMaterialsHelpModalProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog
      title={i18n.translation.wallMaterials.plural}
      isOpen={isOpen}
      onClose={onClose}
      actions={[{ title: i18n.translation.common.close, onClick: onClose }]}
      fitContent
    >
      <div className={styles.small}>
        {items.map((item) => (
          <Fragment key={item.id}>
            <BorderBox onClick={onSelect ? () => onSelect(item) : undefined} showIsMissing={true} title={item.name}>
              <div className={styles.imgSmall}>
                {item.image ? (
                  <BorderBox.Image
                    src={`${import.meta.env.VITE_BUCKET_URL}${item.image}?${getMinutesRoundedTimestamp()}`}
                  />
                ) : (
                  BorderBox.PlaceHolder
                )}
              </div>
            </BorderBox>
          </Fragment>
        ))}
      </div>
    </ModalDialog>
  );
};
