import { Translation } from "../hooks/useTranslations";

const fr: Translation = {
  languageCode: "fr",
  projects: {
    addTo: "Ajouter au projet",
    all: "Tous les projets",
    new: "Nouveau projet",
    singular: "Projet",
    plural: "Projets",
    my: "Mes projets",
    createNew: "Créer un nouveau projet",
    delete: "Supprimer le projet",
    search: "Rechercher des projets",
    shareWithUser: "Partager le projet avec un utilisateur",
    editPermissions: "Modifier les permissions",
    permissionToEdit: "Permission de modifier",
    editSharings: "Modifier les partages",
    sharings: "Partages de projet",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce projet?",
      unshare: "Voulez-vous vraiment annuler le partage du projet avec cet utilisateur?",
    },

    errors: {
      deleteProjectWithConfigurationsNotPossible:
        "Il n'est pas possible de supprimer un projet s'il contient des configurations.",
      deleteProjectWithDocumentsNotPossible:
        "Il n'est pas possible de supprimer un projet s'il contient des documents.",
      notFound: "Projet non trouvé.",
      missingName: "Le nom du projet est manquant.",
      sharingNotFound: "Partage non trouvé.",
      documentNotFound: "Document non trouvé.",
      missingDocumentName: "Nom du document manquant.",
      documentAlreadyPresent: "Document déjà présent.",
      missingProjectStatus: "Le statut du projet est manquant.",
    },
  },

  configurations: {
    all: "Toutes les configurations",
    new: "Nouvelle configuration",
    plural: "Configurations",
    create: "Créer une configuration",
    createNew: "Créer une nouvelle configuration",
    delete: "Supprimer la configuration",
    duplicate: "Dupliquer la configuration",
    my: "Mes configurations",
    search: "Rechercher des configurations",
    reset: "Réinitialiser la configuration",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette configuration?",
      reset: "Voulez-vous vraiment réinitialiser toutes les données de la configuration actuelle ?",
    },

    errors: {
      notFound: "Configuration non trouvée.",
      missingName: "Le nom de la configuration est manquant.",
      missingNormative: "La norme de la configuration est manquante.",
      missingSteelVersion: "La version d'acier de la configuration est manquante.",
      missingUpperSupportInstallationType:
        "Le type d'installation de la support supérieure de la configuration est manquant.",
      missingUpperSupport: "Support supérieure de la configuration est manquante.",
      missingAbsorber: "L'absorbeur de la configuration est manquant.",
      missingSystemType: "Le type de système de la configuration est manquant.",
      missingCable: "Le câble de la configuration est manquant.",
    },
  },

  documents: {
    add: "Ajouter un document",
    all: "Tous les documents",
    alreadyPresent: "Document déjà présent.",
    plural: "Documents",
    delete: "Supprimer un document",
    search: "Rechercher des documents",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce document?",
      overwrite: "Voulez-vous écraser le document déjà présent?",
    },

    errors: {
      someCouldNotBeSaved: "Certains documents n'ont pas pu être enregistrés",
    },
  },

  systems: {
    all: "Tous les systèmes",
    new: "Nouveau système",
    singular: "Système",
    plural: "Systèmes",
    delete: "Supprimer le système",
    search: "Rechercher des systèmes",
    our: "Nos systèmes",
    vertigrip: "Système Vertigrip",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce système?",
    },

    errors: {
      notFound: "Système non trouvé.",
      missingName: "Le nom du système est manquant.",
      missingDescriptionShort: "La description courte du système est manquante.",
      missingDescriptionLong: "La description longue du système est manquante.",
      inUseByConfiguration: "Le système est encore utilisé par une configuration.",
    },
  },

  users: {
    add: "Ajouter un utilisateur",
    all: "Tous les utilisateurs",
    plural: "Utilisateurs",
    remove: "Supprimer l'utilisateur",
    search: "Rechercher des utilisateurs",
    usersEmail: "E-mail des utilisateurs",
    usersName: "Nom des utilisateurs",

    questions: {},

    errors: {
      notFound: "Utilisateur non trouvé.",
    },
  },

  items: {
    all: "Tous les articles",
    singular: "Article",
    plural: "Articles",
    createNew: "Créer un nouvel article",
    delete: "Supprimer l'article",
    search: "Rechercher des articles",
    list: "Liste des articles",

    questions: {
      delete: "Voulez-vous vraiment supprimer cet article?",
    },

    errors: {
      notFound: "Article non trouvé.",
      doesNotExist: "L'article avec ce code n'existe pas.",
      missingName: "Le nom de l'article est manquant.",
      missingDescription: "La description de l'article est manquante.",
      missingCode: "Le code de l'article est manquant.",
    },
  },

  absorbers: {
    singular: "Absorbeur",
    plural: "Absorbeurs",
    createNew: "Créer un nouvel absorbeur",
    delete: "Supprimer l'absorbeur",
    edit: "Modifier l'absorbeur",
    searchs: "Rechercher des absorbeurs",

    questions: {
      delete: "Voulez-vous vraiment supprimer cet absorbeur?",
    },

    errors: {
      alreadyPresent: "Absorbeur déjà présent.",
      notFound: "Absorbeur non trouvé.",
      noDefined: "Aucun absorbeur défini.",
      inUseByConfiguration: "L'absorbeur est encore utilisé par une configuration.",
    },
  },

  cables: {
    createNew: "Créer un nouveau câble",
    delete: "Supprimer le câble",
    edit: "Modifier le câble",
    search: "Rechercher des câbles",
    singular: "Câble",
    plural: "Câbles",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce câble?",
    },

    errors: {
      alreadyPresent: "Câble déjà présent.",
      notFound: "Câble non trouvé.",
      noDefined: "Aucun câble défini.",
      inUseByConfiguration: "Le câble est encore utilisé par une configuration.",
    },
  },

  handles: {
    addItemAsVertigrip: "Ajouter l'article en tant que poignée Vertigrip",
    delete: "Supprimer la poignée",
    edit: "Modifier la poignée",
    singular: "Poignée",
    plural: "Poignées",
    search: "Rechercher des poignées",
    noHandle: "Aucune poignée",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette poignée?",
    },

    errors: {
      alreadyPresent: "Poignée déjà présente.",
      notFound: "Poignée non trouvée.",
      inUseByConfiguration: "La poignée est encore utilisée par une configuration.",
      inUseByUpperExtension: "La poignée est encore utilisée par une extension supérieure.",
    },
  },

  intermediateElements: {
    createNew: "Créer un nouvel élément intermédiaire",
    delete: "Supprimer l'élément intermédiaire",
    edit: "Modifier l'élément intermédiaire",
    singular: "Élément intermédiaire",
    plural: "Éléments intermédiaires",
    search: "Rechercher des éléments intermédiaires",

    questions: {
      delete: "Voulez-vous vraiment supprimer cet élément intermédiaire?",
    },

    errors: {
      alreadyPresent: "Élément intermédiaire déjà présent.",
      notFound: "Élément intermédiaire non trouvé.",
      inUseByConfiguration: "L'élément intermédiaire est encore utilisé par une configuration.",
    },
  },

  ladderReinforcements: {
    createNew: "Créer un nouveau renfort d'échelle",
    delete: "Supprimer le renfort d'échelle",
    edit: "Modifier le renfort d'échelle",
    singular: "Renfort d'échelle",
    plural: "Renforts d'échelle",
    search: "Rechercher des renforts d'échelle",
    noLadderReinforcement: "Aucun renfort d'échelle",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce renfort d'échelle?",
    },

    errors: {
      alreadyPresent: "Renfort d'échelle déjà présent.",
      notFound: "Renfort d'échelle non trouvé.",
      inUseByConfiguration: "Renfort d'échelle est encore utilisé par une configuration.",
    },
  },

  wallMaterials: {
    createNew: "Créer un nouveau matériau de mur",
    delete: "Supprimer le matériau de mur",
    singular: "Matériau de mur",
    plural: "Matériaux de mur",
    search: "Rechercher des matériaux de mur",
    noWallMaterial: "Aucun matériau de mur",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce matériau de mur?",
    },

    errors: {
      notFound: "Matériau de mur non trouvé.",
      missingName: "Le nom du matériau de mur est manquant.",
      missingDescription: "La description du matériau de mur est manquante.",
      inUseByConfiguration: "Le matériau de mur est encore utilisé par une configuration.",
    },
  },

  wallMaterialFixings: {
    createNew: "Créer un nouveau fixation",
    delete: "Supprimer la fixation",
    singular: "Fixation",
    plural: "Fixations",
    search: "Rechercher des fixations",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette fixation?",
    },

    errors: {
      notFound: "Fixation non trouvée.",
      missingName: "Le nom de la fixation est manquant.",
      missingDescription: "La description de la fixation est manquante.",
      inUseByConfiguration: "La fixation est encore utilisée par une configuration.",
    },
  },

  wallMaterialFixingItems: {
    errors: {
      alreadyPresent: "Fixation déjà présente.",
    },
  },

  lowerElements: {
    createNew: "Créer un nouvel élément inférieur",
    delete: "Supprimer l'élément inférieur",
    edit: "Modifier l'élément inférieur",
    singular: "Élément inférieur",
    plural: "Éléments inférieurs",
    search: "Rechercher des éléments inférieurs",

    questions: {
      delete: "Voulez-vous vraiment supprimer cet élément inférieur?",
    },

    errors: {
      alreadyPresent: "Élément inférieur déjà présent.",
      notFound: "Élément inférieur non trouvé.",
      inUseByConfiguration: "L'élément inférieur est encore utilisé par une configuration.",
    },
  },

  shuttles: {
    addItemAsVertigrip: "Ajouter l'article en tant que navette Vertigrip",
    delete: "Supprimer la navette",
    edit: "Modifier la navette",
    singular: "Navette",
    plural: "Navettes",
    search: "Rechercher des navettes",
    no: "Aucune navette",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette navette?",
    },

    errors: {
      alreadyPresent: "Navette déjà présente.",
      notFound: "Navette non trouvée.",
      inUseByConfiguration: "Navette est encore utilisé par une configuration.",
    },
  },

  installationTypes: {
    plural: "Types d'installation",
    singular: "Type d'installation",
    search: "Rechercher des types d'installation",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce type d'installation?",
    },

    errors: {
      notFound: "Type d'installation non trouvé.",
      missingName: "Le nom du type d'installation est manquant.",
      missingDescription: "La description du type d'installation est manquante.",
      noDefined: "Aucun type d'installation défini.",
      inUseByConfiguration: "Le type d'installation est encore utilisé par une configuration.",
      inUseByUpperExtension: "Le type d'installation est encore utilisé par une extension supérieure.",
      inUseByLowerElement: "Le type d'installation est encore utilisé par un élément inférieur.",
      inUseByIntermediateElement: "Le type d'installation est encore utilisé par un élément intermédiaire.",
      inUseByLadderReinforcement: "Le type d'installation est encore utilisé par un renfort d'échelle.",
    },
  },

  steelVersions: {
    createNew: "Créer une nouvelle version d'acier",
    delete: "Supprimer la version d'acier",
    singular: "Version d'acier",
    plural: "Versions d'acier",
    search: "Rechercher des versions d'acier",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette version d'acier?",
    },

    errors: {
      notFound: "Version d'acier non trouvée.",
      missingName: "Le nom de la version d'acier est manquant.",
      missingDescription: "La description de la version d'acier est manquante.",
      noDefined: "Aucune version d'acier définie.",
      inUseByConfiguration: "La version d'acier est encore utilisée par une configuration.",
      inUseByUpperExtension: "La version d'acier est encore utilisée par une extension supérieure.",
      inUseByLowerElement: "La version d'acier est encore utilisée par un élément inférieur.",
      inUseByIntermediateElement: "La version d'acier est encore utilisée par un élément intermédiaire.",
      inUseByAsorber: "La version d'acier est encore utilisée par un absorbeur.",
    },
  },

  upperExtensions: {
    createNew: "Créer une nouvelle extension supérieure",
    delete: "Supprimer l'extension supérieure",
    edit: "Modifier l'extension supérieure",
    singular: "Extension supérieure",
    plural: "Extensions supérieures",
    search: "Rechercher des extensions supérieures",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette extension supérieure?",
    },

    errors: {
      alreadyPresent: "Extension supérieure déjà présente.",
      notFound: "Extension supérieure non trouvée.",
      noDefined: "Aucune extension supérieure définie.",
      inUseByConfiguration: "L'extension supérieure est encore utilisée par une configuration.",
    },
  },

  normatives: {
    createNew: "Créer une nouvelle norme",
    delete: "Supprimer la norme",
    singular: "Norme",
    plural: "Normes",
    search: "Rechercher des normes",

    questions: {
      delete: "Voulez-vous vraiment supprimer cette norme?",
    },

    errors: {
      notFound: "Norme non trouvée.",
      missingName: "Le nom de la norme est manquant.",
      noDefined: "Aucune norme définie.",
      inUseByConfiguration: "La norme est encore utilisée par une configuration.",
    },
  },

  systemTypes: {
    singular: "Type de système",
    plural: "Types de système",
    search: "Rechercher des types de système",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce type de système?",
    },

    errors: {
      notFound: "Type de système non trouvé.",
      missingName: "Le nom du type de système est manquant.",
      missingDescription: "La description du type de système est manquante.",
      noDefined: "Aucun type de système défini.",
      inUseByConfiguration: "Le système est encore utilisé par une configuration.",
      inUseByShuttle: "Le système est encore utilisé par une navette.",
      inUseByIntermediateElement: "Le système est encore utilisé par un élément intermédiaire.",
    },
  },

  texts: {
    singular: "Texte",
    plural: "Textes",
    search: "Rechercher des textes",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce texte?",
    },

    errors: {
      alreadyPresent: "Le texte est déjà présent.",
      notFound: "Texte non trouvé.",
      missingId: "L'ID du texte est manquant.",
      missingValue: "La valeur du texte est manquante.",
    },
  },

  clients: {
    all: "Tous les clients",
    plural: "Clients",
    createNew: "Créer un nouvel client",
    delete: "Supprimer l'client",
    search: "Rechercher des clients",

    questions: {
      delete: "Voulez-vous vraiment supprimer ce système?",
    },

    errors: {
      notFound: "Client non trouvé.",
      missingName: "Nom d'client manquant.",
      insertNotAllowed: "Vous n'êtes pas autorisé à insérer un nouvel client.",
      readNotAllowed: "Vous n'êtes pas autorisé à lire cet client.",
      updateNotAllowed: "Vous n'êtes pas autorisé à mettre à jour cet client.",
      deleteNotAllowed: "Vous n'êtes pas autorisé à supprimer l'client.",
    },
  },

  roles: {
    plural: "Rôles",
  },

  permissions: {
    plural: "Permissions",
  },

  secrets: {
    plural: "Secrets",

    errors: {
      missingName: "Nom d'secret manquant.",
      missingValue: "Valeur d'secret manquant.",
    },
  },

  common: {
    active: "Actif",
    add: "Ajouter",
    addNewItem: "Ajouter un nouvel article",
    address: "Adresse",
    administration: "Administration",
    alreadyPresent: "Déjà présent",
    archived: "Archivé",
    atLeastOneItemNeeded: "Au moins un article est nécessaire.",
    author: "Auteur",
    automaticSync: "Synchronisation automatique",
    back: "Retour",
    baseHeight: "Hauteur de base",
    cancel: "Annuler",
    close: "Fermer",
    city: "Ville",
    code: "Code",
    company: "Entreprise",
    confirmed: "Confirmé",
    contactPerson: "Personne de contact",
    contactPersonWarning:
      "La saisie de ces données est volontaire et se fait sous l'entière responsabilité de l'utilisateur. Il n'est pas recommandé d'introduire des données qui identifient ou rendent identifiables des personnes.",
    cookiesText:
      "Ce site web utilise des cookies et respecte votre vie privée. En cliquant sur <strong>Ok, j'ai lu</strong> que vous êtes conscient des cookies utilisés par notre site comme spécifié dans la politique de cookies.",
    coordinates: "Coordonnées",
    copy: "Copie",
    country: "Pays",
    create: "Créer",
    created: "Créé",
    createNew: "Créer nouveau",
    dataHasBeenSavedSuccessfully: "Les données ont été enregistrées avec succès.",
    decrease: (value: number) => `Diminuer ${value}`,
    download: "Télécharger",
    delete: "Supprimer",
    description: "Description",
    descriptionLong: "Description (longue)",
    descriptionShort: "Description (courte)",
    details: "Détails",
    dragAndDropOrUploadAnImage: "Glissez-déposez ou cliquez pour télécharger une image",
    dropIt: "Déposez-le !",
    duplicate: "Dupliquer",
    edit: "Modifier",
    editor: "Éditeur",
    email: "E-mail",
    error: "Erreur",
    errorLoadingData: "Erreur de chargement des données",
    excel: "Excel",
    failed: "Échoué",
    fieldCannotBeEmpty: "Le champ ne peut pas être vide.",
    fieldContainsInvalidValueInsertBiggerOne: "Le champ contient une valeur invalide, insérez une valeur plus grande.",
    fieldContainsInvalidValueInsertSmallerOne: "Le champ contient une valeur invalide, insérez une valeur plus petite.",
    fieldValueInvalidFormat: "La valeur a un format invalide.",
    fieldInvalidValue: "Le champ contient une valeur invalide.",
    fixed: "Fixe",
    forbidden: "Non autorisé",
    generalData: "Données générales",
    genericError: "Une erreur est survenue, veuillez réessayer.",
    heightToReach: "Hauteur à atteindre",
    hidden: "Caché",
    home: "Accueil",
    increase: (value: number) => `Augmenter ${value}`,
    installed: "Installé",
    intermediateSupport: "Support intermédiaire",
    isVisible: "Est visible",
    ladderExitDifference: "Difference sortie échelle",
    languages: {
      singular: "Langue",
      plural: "Langues",
      arabic: "Arabe",
      czech: "Tchèque",
      danish: "Danois",
      german: "Allemand",
      greek: "Grec",
      english: "Anglais",
      spanish: "Espagnol",
      estonian: "Estonien",
      finnish: "Finnois",
      french: "Français",
      croatian: "Croate",
      hungarian: "Hongrois",
      icelandic: "Islandais",
      italian: "Italien",
      japanese: "Japonais",
      lithuanian: "Lituanien",
      latvian: "Letton",
      dutch: "Néerlandais",
      norwegian: "Norvégien",
      polish: "Polonais",
      portugese: "Portugais",
      russian: "Russe",
      slovak: "Slovaque",
      slovenian: "Slovène",
      swedish: "Suédois",
      turkish: "Turc",
      chinese: "Chinois",
      korean: "Coréen",
    },
    lastAccess: "Dernier accès",
    lastModified: "Dernière modification",
    lastSync: "Dernière synchronisation",
    loading: "Chargement...",
    location: "Emplacement",
    logout: "Se déconnecter",
    lowerSupport: "Support inférieure",
    minConfigurableQuantity: "Quantité minimale configurable",
    minimumOrderQuantity: "Quantité minimale de commande",
    minOrderQuantity: "Qté min commande",
    moreInformation: "Plus d'informations",
    myArea: "Mon espace",
    myProfile: "Mon profil",
    name: "Nom",
    next: "Suivant",
    never: "jamais",
    newConfigurationName: "Nouveau nom configuration",
    nextNRows: (n: number) => `Lignes suivantes ${n}`,
    no: "Non",
    noItemsFound: "Aucun élément trouvé.",
    notFound: "Non trouvé",
    nrIntermediatesAndSpan: "Nbre d'intermédiaires et portée",
    nrOfFixingItemsRequired: "Nombre d'éléments de fixation requis (par matériau de mur)",
    offer: "Offre",
    ok: "Ok",
    okIreadIt: "Ok, j'ai lu",
    onlyImagesAreAllowed: "Seules les images sont autorisées.",
    open: "Ouvrir",
    optional: "optionnel",
    order: "Ordre",
    overwrite: "Écraser",
    previousNRows: (n: number) => `Lignes précédentes ${n}`,
    print: "Imprimer",
    qty: "Qté.",
    removable: "Retirable",
    remove: "Retirer",
    removeImage: "Retirer l'image",
    reset: "Réinitialiser",
    resetToDefault: "Réinitialiser par défaut",
    resourceNotFound: "La ressource que vous recherchez est introuvable.",
    save: "Enregistrer",
    search: "Rechercher",
    select: "Sélectionner",
    selectProjectFirst: "Sélectionner un projet d'abord",
    selectProjectInwhichCreateCopy: "Sélectionnez le projet dans lequel créer la copie",
    sessionExpired: "Session expiré",
    sessionExpiredText: "Veuillez vous reconnecter pour continuer à utiliser l'application.",
    settings: "Paramètres",
    settingsSystem: (system: string) => `Paramètres : ${system}`,
    status: "Statut",
    sync: "Synchroniser",
    targa: "Targa",
    testEnvironment: "Environnement de test",
    toBeInstalled: "À installer",
    toInspect: "À inspecter",
    uploadedAt: "Téléchargé",
    uploadedBy: "Téléchargé par",
    upperSupport: "Support supérieure",
    value: "Valeur",
    visible: "Visible",
    yes: "Oui",
    youAreNotAllowed: "Vous n'êtes pas autorisé à accéder à cette ressource.",
  },
};

export default fr;
